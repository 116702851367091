import { createElement } from './createElement';

export const initHead = (): void => {
  const baseUrl = process.env.REACT_APP_SPORTSBOOK_JS_URL ?? '';

  const cssFiles = ['/preflight.css', '/styles.css', '/root-styles.css', '/assets/theme.css'];

  cssFiles.forEach((path) => {
    createElement(
      'link',
      [
        { name: 'data-mggcv2-asset', value: 'true' },
        { name: 'href', value: `${baseUrl}${path}` },
        { name: 'rel', value: 'stylesheet' },
      ],
      'head'
    );
  });

  createElement(
    'meta',
    [
      { name: 'data-mggcv2-asset', value: 'true' },
      { name: 'name', value: 'importmap-type' },
      { name: 'content', value: 'systemjs-importmap' },
    ],
    'head'
  );

  createElement(
    'script',
    [
      { name: 'data-mggcv2-asset', value: 'true' },
      { name: 'type', value: 'systemjs-importmap' },
      { name: 'src', value: `${baseUrl}/importmap.json` },
    ],
    'head'
  );
};

export const initHTML = (): void => {
  const init = document.createElement('script');

  init.innerHTML = `System.import("@metricgaming/gc-v2-root-config");`;
  init.setAttribute('id', 'mggcv2-import');
  init.setAttribute('data-mggcv2-asset', 'true');
  document.getElementsByTagName('body')[0].appendChild(init);
};
