import React, { FunctionComponent, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { GetBonusHistoryParams } from '../../../models/bonuses.model';
import { addMonths, formatDate, formatISO } from 'date-fns';
import { AccountPageWrapperProps } from '../MyAccount/AccountPageWrapper';
import { MyBonusHeading } from './MyBonusHeading';
import { MyBonusSummary } from './MyBonusSummary';
import { DateRange, DateRangePayload } from '../../shared/DateRange';
import { Heading } from '../../shared/Heading';
import { InnerHTML } from '../../shared/InnerHTML';
import { TabularDatalist } from '../../shared/TabularDatalist';
import { joinStrings } from '../../../utils/string';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../../hooks/snackbar';
import useDestroyUserSession from '../../../hooks/auth/useDestroyUserSession';
import { APP_URLS } from '../../../consts';
import { useAuth } from 'react-oidc-context';
import './styles';
import { ParsedPromo } from '../../Layout/Promotions/types';
import { useTranslations } from '../../../hooks/useTranslationsHelper';

export type PromotionHistoryType = {
  withinPromotion?: boolean;
  promotions: ParsedPromo[];
};

export type MyBonusHistoryProps = Pick<AccountPageWrapperProps, 'elementToFocusOnOpen' | 'onClose'> &
  PromotionHistoryType;

export const MyBonusHistory: FunctionComponent<MyBonusHistoryProps> = ({ withinPromotion, promotions }) => {
  const paginationNumber = 5;

  const { t } = useTranslations();

  const [bonuses, bonusesSet] = useState<ParsedPromo[]>([]);
  const [totalBonuses, setTotalBonuses] = useState<ParsedPromo[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [total, setTotal] = useState<number | undefined>();
  const [collapseItem, setCollapseItem] = useState<boolean>(true);

  const { logoutUser } = useDestroyUserSession();

  const [dateRange, dateRangeSet] = useState({
    startDate: addMonths(new Date(), -1),
    endDate: new Date(),
  });

  const auth = useAuth();
  const isUserAuthenticated = auth?.isAuthenticated;

  const cardRefs: RefObject<HTMLDivElement>[] = bonuses.filter(Boolean).map(() => React.createRef());

  const navigate = useNavigate();
  const { addSnack } = useSnackbar();

  const filters = useRef<Pick<GetBonusHistoryParams, 'skip'>>({
    skip: 0,
  });

  const handleGetActivePromotions = useCallback(
    async ({
      viewMore,
    }: {
      viewMore?: boolean;
    } = {}): Promise<void> => {
      const dateFilteredPromotions = promotions.filter((promotion) => {
        const createdDate = String(promotion.createdOn);

        return createdDate <= formatISO(dateRange.endDate) && createdDate >= formatISO(dateRange.startDate);
      });

      filters.current.skip = viewMore ? bonuses.length : 0;

      try {
        setTotalBonuses(dateFilteredPromotions);
        bonusesSet(dateFilteredPromotions.slice(0, paginationNumber));
        setTotal(dateFilteredPromotions.length);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        if (e.errors[0].status === '401' || e.status === 401) {
          // If request returns 401 for paging or transactions redirect to home page with login open.
          // TODO: Should there be global handling of this?
          logoutUser();
          navigate(APP_URLS.home);
        } else {
          bonusesSet([]);
          setTotal(0);
          /**
           * 404 Error:
           *   Indicates that there are no results or paging is incorrect.
           *
           * Other errors e.g. 503
           *   All requests display generic error
           */

          if (
            (e.errors[0].code !== 'not-found' && // Get Request hasn't returned a 404
              e.status !== 404) || // HEAD request hasn't returned a 404
            viewMore // OR request is via 'view more' button
          ) {
            addSnack({
              type: 'error',
              message: 'Sorry, something went wrong. Please try again later.',
            });
          }
        }
      } finally {
        setIsFetching(false);
      }
    },
    [dateRange, addSnack, bonuses, logoutUser, navigate, isUserAuthenticated]
  );

  useEffect(() => {
    handleGetActivePromotions();
    setCollapseItem(!collapseItem);
  }, [dateRange]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleDateRangeAction({ startDate, endDate }: DateRangePayload): void {
    if (startDate && endDate) {
      dateRangeSet({
        startDate,
        endDate,
      });
    }
  }

  function loadMoreBonuses(): void {
    bonusesSet(totalBonuses.slice(0, bonuses.length + paginationNumber));
  }

  const structure = (): JSX.Element => (
    <>
      <div
        className={joinStrings([withinPromotion ? 'promotion-bonus-history__heading' : 'my-bonus-history__heading'])}
      >
        <Heading level={3}>{withinPromotion ? 'My bonus activity' : 'My Bonus Activity'}</Heading>

        <div
          className={joinStrings([
            withinPromotion ? 'promotion-bonus-history__daterange' : 'my-bonus-history__daterange',
          ])}
        >
          <DateRange
            startDateTitle={t('my-account.home.my-activity.start-date')}
            endDateTitle={t('my-account.home.my-activity.end-date')}
            dateFormat="dd/MMM/yyyy"
            onAction={handleDateRangeAction}
            startingDate={dateRange.startDate}
            endingDate={dateRange.endDate}
          />
        </div>
      </div>

      <div
        className={joinStrings([
          withinPromotion ? 'promotion-bonus-history__content' : 'my-bonus-history__content',
          !bonuses.length && 'my-bonus-history__no-bonuses',
        ])}
      >
        <div className="tdl__total-results" data-testid="tdl-total-results">
          {bonuses.length > 0 && (
            <InnerHTML
              text={
                withinPromotion
                  ? `1 - ${bonuses.length} of ${total} Result`
                  : `1 - ${bonuses.length} of ${total} Result ${formatDate(dateRange.startDate, 'dd/MM/yyyy')} - ${formatDate(dateRange.endDate, 'dd/MM/yyyy')}`
              }
            />
          )}
        </div>
        <TabularDatalist
          showCount={false}
          totalResults={total || 0}
          isLoading={isFetching}
          noResultsMessage={t('search.no-results')}
          onLoadMore={loadMoreBonuses}
          showLoadMore={bonuses.length !== 0 && typeof total !== 'undefined' && bonuses.length < total}
          dataset={bonuses.map((bonus, index) => ({
            panel: (
              <MyBonusSummary ref={cardRefs[index]}>
                <MyBonusHeading {...bonus} />
              </MyBonusSummary>
            ),
          }))}
          collapseItem={collapseItem}
        />
      </div>
    </>
  );

  const elements = structure();

  return <div className="promotion-bonus-history">{elements}</div>;
};
