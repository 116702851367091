import {
  PlayerActivitiesStatisticsData,
  TransactionCategory,
  TransactionHistoryActivity,
} from '../../../models/transactions.model';
import { addMonths, format } from 'date-fns';
import React, { FC, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { MyAccountActivityTotals } from './MyAccountActivityTotals';
import {
  generateCSV,
  getPotentialPayout,
  mergeTransactionsWithCoupons,
  removeDuplicateTransactions,
  skipTime,
} from './helper';
import { IncomingIcon, MyAccountActivityIcon, OutgoingIcon } from './MyAccountActivityIcons';
import { betsDisplayType, transactionCategories, withdrawalPendingDisplayType } from './consts';
import { MyAccountActivityPanel } from './MyAccountActivityPanel';
import { MyAccountActivityContent } from './MyAccountActivityContent';
import { MyAccountActivityProps, PromoCancelStatus, ValidSubType } from './types';
import { KambiTransactionType } from '../../Kambi/types';
import { DateRange, DateRangePayload } from '../../shared/DateRange';
import { downloadBlob } from '../../../utils/download';
import { Heading } from '../../shared/Heading';
import { Paragraph } from '../../shared/Paragraph';
import { TabularDatalist } from '../../shared/TabularDatalist';
import { ToggleButton } from '../../shared/ToggleButton';
import { useDeviceInfo } from '../../../hooks/useDeviceInfo';
import { useSnackbar } from '../../../hooks/snackbar';
import { Prompt } from '../../shared/Prompt';
import { AccountPageWrapper } from '../MyAccount/AccountPageWrapper';
import { TabularDataDetailsItem } from '../../shared/TabularDatalist/TabularDataDetailsItem';
import { POSITIVE_ADJUSTMENT_COUPON_STATUSES } from '../../Kambi/consts';
import { Spinner } from '../../shared/Spinner';
import { useUserContext } from '../../../providers/UserProvider/UserProvider';
import { useFetchPromotionsActive } from '../../../hooks/useFetchPromotionsActive';
import { getActivityStatistics, getTransactionHistoryPagingResponse, getTransactionHistoryResponse } from './mockData';
import { DownloadIcon } from '../../shared/SVG/Icons/DownloadIcon';
import './styles';
import { BonusHistoryItem } from '../../../models/bonuses.model';
import { useKambi } from '../../../hooks/useFetchKambi/useFetchKambi';
import { useTranslations } from '../../../hooks/useTranslationsHelper';

export const MyAccountActivity: FC<MyAccountActivityProps> = (props) => {
  // Hooks
  const { t } = useTranslations();
  const { addSnack } = useSnackbar();
  // Replace with real image
  const assetsUrl = '/static/media';

  const { isIos, isVersion } = useDeviceInfo();
  const version12 = isVersion(12);

  const turnOffTotalDepositsAndWithdrawals = false;
  const cancelWithdrawalEnabled = false;

  // State
  const [downloadCSV, setDownloadCSV] = useState<string>('');
  const [forceUpdate, setForceUpdate] = useState<boolean>(false);
  const [downloadPrompt, setDownloadPrompt] = useState(false);
  const [downloadReady, setDownloadReady] = useState(false);
  const [playerActivityStatistics, setPlayerActivityStatistics] =
    useState<Nullable<PlayerActivitiesStatisticsData>>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<TransactionHistoryActivity[]>([]);
  const [resultTransactions, setResultTransactions] = useState<TransactionHistoryActivity[]>([]);
  const [totalTransactions, setTotalTransactions] = useState<number>(0);

  // Stored according to the users current timezone. Only correct to the date, not the time.
  const [dateRange, setDateRange] = useState({
    startDate: addMonths(new Date(), -1),
    endDate: new Date(),
  });
  const filters = useRef<{ category: TransactionCategory[]; skip: number }>({
    category: [],
    skip: 0,
  });

  // Selectors
  const { user } = useUserContext();
  const { bonuses } = useFetchPromotionsActive();
  const { getKambiCoupons } = useKambi();
  const isGIBBrand = false;
  const switchableCondition = isGIBBrand && cancelWithdrawalEnabled;
  const showDownloadButton = !(isIos && version12) && filters.current.category === undefined && transactions.length > 0;

  const { depositsAmount, depositsCount, withdrawalsAmount, withdrawalsCount } = useMemo(
    () =>
      playerActivityStatistics
        ? playerActivityStatistics.banking
        : {
            depositsAmount: 0,
            withdrawalsAmount: 0,
            withdrawalsCount: 0,
            depositsCount: 0,
          },
    [playerActivityStatistics]
  );

  const transactionsWithoutWithdrawPending = useMemo(
    () => transactions.filter((transaction) => transaction.displayType !== withdrawalPendingDisplayType),
    [transactions]
  );

  useEffect(() => {
    const result = switchableCondition ? transactionsWithoutWithdrawPending : transactions;

    getKambiCoupons()
      .then((couponsData) => {
        setResultTransactions(mergeTransactionsWithCoupons(result, couponsData));
      })
      .catch((error: string) => {
        console.error('Loading coupons history failed:', error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switchableCondition, transactions, transactionsWithoutWithdrawPending]);

  const resetDownloadPrompt = (): void => {
    setDownloadPrompt(false);
    setDownloadReady(false);
  };

  const downloadHistory = async (): Promise<void> => {
    setDownloadPrompt(true);
    setDownloadReady(false);
    try {
      setDownloadCSV(generateCSV(transactions, 'GP', () => ''));

      // change to ready state
      setDownloadReady(true);
    } catch (e) {
      addSnack({
        type: 'error',
        message: t('my-account.home.my-activity.transaction-error'),
      });
      resetDownloadPrompt();
    }
  };

  const downloadList = async (): Promise<void> => {
    try {
      if (downloadCSV) {
        const filename = `transaction_history_${format(new Date(), 'dd/MM/yyyy')}.csv`;
        const blob = new Blob([downloadCSV]);

        downloadBlob(blob, filename);
      }
    } catch (e) {
      addSnack({
        type: 'error',
        message: t('my-account.home.my-activity.transaction-error'),
      });
    }

    resetDownloadPrompt();
  };

  /**
   * Fetches transactions for display in the table.
   * @param viaViewMore Options for different fetch contexts
   */
  const getTransactions = async ({
    viaViewMore,
  }: {
    viaViewMore?: boolean;
  } = {}): Promise<void> => {
    setIsFetching(true);

    const categoryFilter = filters.current.category;

    const noPendingTransactions = getTransactionHistoryResponse().filter((responseItem: TransactionHistoryActivity) => {
      const startDate = skipTime(dateRange.startDate);
      const endDate = skipTime(dateRange.endDate);
      const transactionDate = skipTime(new Date(responseItem.transactionDate));

      return transactionDate >= startDate && transactionDate <= endDate && responseItem.displayType !== 'Pending';
    });
    const activityStatistics = getActivityStatistics();
    const transactionHistoryPagingResponse = getTransactionHistoryPagingResponse();

    try {
      const [paging, results, activitySummary] = [
        transactionHistoryPagingResponse,
        noPendingTransactions,
        activityStatistics,
      ];
      const transactionRequestStep = paging.itemsPerPage;

      filters.current.skip = filters.current.skip + transactionRequestStep;
      const slicedTransactions = removeDuplicateTransactions(results);

      const preFilteredTransactions = categoryFilter.length
        ? slicedTransactions.filter((transaction) => categoryFilter.includes(transaction.details.category))
        : slicedTransactions;

      const filteredTransactions = preFilteredTransactions.slice(0, filters.current.skip);

      setTransactions(filteredTransactions);
      setTotalTransactions(preFilteredTransactions.length || 0);
      setPlayerActivityStatistics(activitySummary);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.errors[0].status === '401' || e.status === 401) {
        // Do nothing - let SpineErrorHandler take care of this.
      } else {
        setTransactions([]);
        setTotalTransactions(0);
        setPlayerActivityStatistics(null);

        /**
         * 404 Error:
         *   Indicates that there are no results or paging is incorrect.
         *
         * Other errors e.g. 503
         *   All requests display generic error
         */
        if (
          (!(e.errors[0].code === 'not-found') && // Get Request hasn't returned a 404
            e.status !== 404) || // HEAD request hasn't returned a 404
          viaViewMore // OR request is via 'view more' button
        ) {
          addSnack({
            type: 'error',
            message: t('my-account.home.my-activity.transaction-handling-error'),
          });
        }
      }
    } finally {
      setIsFetching(false);
    }
  };

  const clearTransactions = (): void => {
    setTransactions([]);
    setTotalTransactions(0);
    filters.current.skip = 0;
  };

  const assignDate = ({ startDate, endDate }: DateRangePayload): void => {
    if (startDate && endDate) {
      clearTransactions();
      setDateRange({ startDate, endDate });
    }
  };

  const withdrawPendingTransactions = useMemo(
    () => transactions.filter((transaction) => transaction.displayType === withdrawalPendingDisplayType),
    [transactions]
  );

  const tabularDataset: (
    | { isWithdrawPending?: undefined; content?: undefined; panel?: undefined }
    | { isWithdrawPending: boolean; content: ReactElement<string, string>; panel: ReactElement<string, string> }
  )[] = useMemo(
    () =>
      resultTransactions.map(({ coupon, details, type, displayType, ...trans }) => {
        if (details.subType?.toLowerCase() === ValidSubType.Failed) {
          return {};
        }

        const potentialPayout = getPotentialPayout(trans?.betting?.betActions?.[0].additionalData);

        const getPromoToClaim = (promoCode: string): BonusHistoryItem | undefined =>
          bonuses.find((promo) => promo.promoCode === promoCode);

        const activatedPromo = getPromoToClaim(details.promoCode || '');
        const isCancelStatuses = Boolean(
          activatedPromo && (PromoCancelStatus as Record<string, string>)[activatedPromo.status]
        );
        const isCancelBtn = Boolean(details.promoCode) && isCancelStatuses;
        const title = t(`transaction-history.${details.category.toLowerCase()}.${type.toLowerCase()}`);

        const status =
          details.category.toLowerCase() === 'banking' &&
          details.subType &&
          (ValidSubType as Record<string, string>)[details.subType]
            ? t(`transaction-history.status.${details?.subType.toLowerCase()}`)
            : '';

        return {
          isWithdrawPending: switchableCondition ? displayType === withdrawalPendingDisplayType : false,
          content: coupon ? (
            <MyAccountActivityContent
              description={coupon.description}
              isCancelBtn={isCancelBtn}
              activatedPromo={activatedPromo as BonusHistoryItem}
              couponRef={coupon.couponRef}
              transactionAmount={trans?.amount}
              isSingleBet={coupon.isSingleBet}
              content={coupon.allProps.map((props, index) => (
                <TabularDataDetailsItem
                  {...props}
                  key={props.number}
                  eventStartDate={coupon.data[index].eventStartDate}
                />
              ))}
              stake={coupon.stake}
              payout={coupon.payout}
              status={coupon.status}
              potentialPayout={coupon.payoutBoosted || potentialPayout}
            />
          ) : (
            <MyAccountActivityContent
              description={trans.description}
              isCancelBtn={isCancelBtn}
              activatedPromo={activatedPromo as BonusHistoryItem}
            />
          ),
          panel: (
            <MyAccountActivityPanel
              title={title}
              status={coupon?.statusText || status}
              amount={trans.amount}
              transactionDate={trans.transactionDate}
              stake={coupon?.stake}
              isFreeBet={coupon?.isFreeBet}
              cashBalanceAfter={trans.cashBalanceAfter ?? 0}
              totalBalanceAfter={trans.totalBalanceAfter ?? 0}
              ActivityIcon={
                <MyAccountActivityIcon
                  amount={trans.amount || 0}
                  // Kambi status don't align with bede status. So force it to be an adjustment
                  // when we know it's a positive adjustment to the balance
                  type={
                    POSITIVE_ADJUSTMENT_COUPON_STATUSES.includes(String(coupon?.status))
                      ? KambiTransactionType.ADJUSTMENT
                      : type
                  }
                  transactionType={type}
                />
              }
            />
          ),
        };
      }),
    [resultTransactions, bonuses, switchableCondition]
  );

  useEffect(() => {
    if (user) {
      // Consider to use yesterday
      const createdDate = new Date(new Date().getDay() - 1);
      const createdMonth = createdDate.getMonth();
      const createdYear = createdDate.getFullYear();

      const currentDate = new Date(Date.now());
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();

      if (createdYear === currentYear && createdMonth === currentMonth) {
        setDateRange({
          startDate: createdDate,
          endDate: new Date(),
        });
      }
    }
  }, [user]);

  useEffect(() => {
    getTransactions();
    // eslint-disable-next-line
  }, [dateRange, forceUpdate]);

  useEffect(() => {
    const result = switchableCondition ? transactionsWithoutWithdrawPending : transactions;

    // Consider to apply Kambi here
    setResultTransactions(result);
  }, [transactions, switchableCondition, transactionsWithoutWithdrawPending]);

  useEffect(() => {
    // Get active promotions here
  }, []);

  return (
    <>
      <AccountPageWrapper {...props} className="my-activity">
        <div className="my-account-activity__heading">
          <Heading level={3}>{t('my-account.my-activity')}</Heading>

          <div className="my-account-activity__daterange">
            <DateRange
              dateFormat="dd/MMM/yyyy"
              startDateTitle={t('my-account.home.my-activity.start-date')}
              endDateTitle={t('my-account.home.my-activity.end-date')}
              startingDate={dateRange.startDate}
              endingDate={dateRange.endDate}
              onAction={assignDate}
              isCustomHeader
              inputBoxClassName="my-account-activity__date-range"
            />
          </div>
          <div body-scroll-lock-ignore="true" className="my-account-activity__toggle-btns">
            {transactionCategories.map(({ value, label }) => {
              const equalizedValue = value === betsDisplayType ? 'Betting' : value;
              const currentCategory = filters.current.category;

              return (
                <ToggleButton
                  size="small"
                  variant="secondary"
                  className="my-account-activity__category-button"
                  onClick={(): void => {
                    if (equalizedValue) {
                      const categoryIndex = currentCategory.indexOf(equalizedValue);

                      ~categoryIndex ? currentCategory.splice(categoryIndex, 1) : currentCategory.push(equalizedValue);
                      filters.current.category = currentCategory;
                    } else {
                      filters.current.category = [];
                    }

                    clearTransactions();
                    getTransactions();
                  }}
                  key={label}
                  selected={equalizedValue ? currentCategory.includes(equalizedValue) : !currentCategory.length}
                >
                  {t(`my-account.home.my-activity.category.${label}`)}
                </ToggleButton>
              );
            })}
          </div>
        </div>

        <div className="my-account-activity__content">
          {filters.current.category?.includes('Banking') &&
            playerActivityStatistics &&
            !turnOffTotalDepositsAndWithdrawals && (
              <>
                {depositsCount > 0 && (
                  <MyAccountActivityTotals
                    Icon={<IncomingIcon />}
                    amount={depositsAmount}
                    quantity={depositsCount}
                    label={t('my-account.home.my-activity.category.total-deposits')}
                  />
                )}
                {withdrawalsCount > 0 && (
                  <MyAccountActivityTotals
                    Icon={<OutgoingIcon />}
                    amount={withdrawalsAmount}
                    quantity={withdrawalsCount}
                    label={t('my-account.home.my-activity.category.total-withdrawals')}
                  />
                )}
              </>
            )}
          <div className="my-account-activity__data">
            {switchableCondition && (
              <>
                {withdrawPendingTransactions.length !== 0 && (
                  <TabularDatalist
                    showCount={false}
                    headDatalist="Pending withdrawals"
                    isLoading={isFetching}
                    onLoadMore={(): Promise<void> => getTransactions({ viaViewMore: true })}
                    forceUpdate={forceUpdate}
                    setForceUpdate={setForceUpdate}
                    totalResults={withdrawPendingTransactions.length}
                    dataset={withdrawPendingTransactions.map((transaction) => {
                      const title = t(
                        `transaction-history.${transaction.details.category.toLowerCase()}.${transaction.type.toLowerCase()}`
                      );

                      return {
                        content: <MyAccountActivityContent description={transaction.description} />,
                        panel: (
                          <MyAccountActivityPanel
                            title={title}
                            amount={transaction.amount}
                            transactionDate={transaction.transactionDate}
                            ActivityIcon={
                              <MyAccountActivityIcon amount={transaction.amount || 0} type={transaction.type} />
                            }
                          />
                        ),
                        isWithdrawPending: transaction.displayType === withdrawalPendingDisplayType,
                        remoteReference: transaction.remoteReference,
                      };
                    })}
                  />
                )}
              </>
            )}
            <div className="my-account-activity__transactions-wrapper">
              {showDownloadButton && (
                <button tabIndex={0} className="my-account-activity__content-download-btn" onClick={downloadHistory}>
                  <DownloadIcon /> &nbsp; Download
                </button>
              )}
              <TabularDatalist
                headDatalist={switchableCondition ? 'Transactions' : ''}
                showCount={!switchableCondition}
                isLoading={isFetching}
                onLoadMore={(): Promise<void> => getTransactions({ viaViewMore: true })}
                showLoadMore={filters.current.skip < totalTransactions}
                totalResults={resultTransactions.length}
                dataset={tabularDataset}
              />
            </div>
          </div>
        </div>
      </AccountPageWrapper>

      {/* Translations: consider wheather to apply sownload functionality */}
      <Prompt
        show={downloadPrompt}
        hasCloseButton={downloadReady}
        busy={!downloadReady}
        onClose={(): void => {
          setDownloadPrompt(false);
        }}
        title={downloadReady ? 'Your report is ready to download!' : 'Your report is being generated'}
        image={
          downloadReady
            ? {
                src: `${assetsUrl}/prompts/report-ready-prompt.png`,
                alt: 'prompt image',
              }
            : {
                src: `${assetsUrl}/prompts/report-generated-prompt.png`,
                alt: 'prompt image',
              }
        }
        PrimaryButton={
          downloadReady ? (
            {
              onClick: downloadList,
              text: 'DOWNLOAD REPORT',
            }
          ) : (
            <Spinner theme="dark" />
          )
        }
      >
        <Paragraph noMargin size="sm">
          {downloadReady
            ? 'Click on the below button to get your transactions history.'
            : "This won't take longer than 2 minutes."}
        </Paragraph>
      </Prompt>
    </>
  );
};
