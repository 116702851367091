import React, { FunctionComponent, useEffect, useRef } from 'react';
import cookie from 'js-cookie';
import { joinStrings } from '../../../utils/string';
import { MediaBreakpoint, useBreakpointInfo } from '../../../hooks/useDeviceInfo';
import { ProductCategoryLink } from '../types';
import { usePersistentHorizontalScroll } from '../../../hooks/presentation-logic';
import { Chip } from '../../shared/Chip';
import './styles';
import { Icon } from '../../shared/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedCategory } from '../../../state/app/reducers';
import { selectSelectedCategory } from '../../../state/app';

type CategoryNavigationProps = {
  categories: ProductCategoryLink[];
  enabled: boolean;
  searchBarContainerRef?: React.RefObject<HTMLDivElement>;
};

export const isAuthLinkEnabled =
  (isUserLoggedIn: boolean) =>
  ({ authenticated, unauthenticated }: Pick<ProductCategoryLink, 'authenticated' | 'unauthenticated'>): boolean =>
    isUserLoggedIn ? !!authenticated : !!unauthenticated;

const scrollSubCategories = (): void => {
  const subCategories = document.getElementById('category__navigation');

  if (subCategories) {
    const position = { left: 0, x: 0 };

    const mouseMoveHandler = (event: MouseEvent): void => {
      const dx = event.clientX - position.x;

      subCategories.scrollLeft = position.left - dx;
    };

    const mouseUpHandler = (): void => {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    };

    const mouseDownHandler = (event: MouseEvent): void => {
      position.left = subCategories.scrollLeft;
      position.x = event.clientX;

      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    };

    subCategories.addEventListener('mousedown', mouseDownHandler);
  }
};

export const CategoryNavigation: FunctionComponent<CategoryNavigationProps> = ({ categories = [], enabled }) => {
  const isUserLoggedIn = false;
  const enabledItems = categories.filter(isAuthLinkEnabled(isUserLoggedIn));
  const isMobile = useBreakpointInfo([MediaBreakpoint.BELOW_MOBILE]);
  const scrollLeft = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const selectedCategory = useSelector(selectSelectedCategory);

  usePersistentHorizontalScroll(scrollLeft, 'category-navigation-horizontal-position');

  const handleClick = (category: string, index: number): void => {
    const containerRect = scrollLeft.current && (scrollLeft.current as HTMLElement).getBoundingClientRect();

    // Calculating position for sub nav element after click
    const centeredElement =
      containerRect && (scrollLeft.current?.children[index] as HTMLElement).offsetLeft - containerRect.left;

    cookie.set('category-navigation-horizontal-position', String(centeredElement));

    dispatch(setSelectedCategory(category));
  };

  useEffect(() => {
    scrollSubCategories();
  }, []);

  useEffect(() => {
    // Remove active category after new categories are received
    dispatch(setSelectedCategory(''));
  }, [categories]);

  if (enabledItems.length === 0 || !enabled) return null;

  return (
    <div ref={scrollLeft} className="category__navigation" data-testid="category-navigation" id="category__navigation">
      {enabledItems.map(({ label, url, icon, iconActive }, index) => (
        <div
          key={`${index}-${url}`}
          className="category__navigation-item"
          onClick={(): void => handleClick(label, index)}
          draggable={false}
        >
          <Chip
            variant="rounded"
            className={joinStrings([selectedCategory === label && 'active', !isMobile && 'category--medium-chip'])}
          >
            {(icon || iconActive) && <Icon variant={icon || iconActive} className="category__navigation-item-icon" />}
            {label}
          </Chip>
        </div>
      ))}
    </div>
  );
};
