/*eslint-disable */
import { addYears, formatDate } from 'date-fns';
import React, { FunctionComponent, JSXElementConstructor, ReactElement } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Icon } from '../Icon';
import { Input } from '../Input';
import { DateInputProps } from './types';
import './DateInput.scss';
import { validDate } from '../../../utils/form';
import { useCustomDatePickerProps } from './useCustomDatePickerProps';

/**
 * TODO: React DatePicker has an issue with keyboard selection of minDate.
 *
 * Opened up github issue here: https://github.com/Hacker0x01/react-datepicker/issues/2405.
 * I've applied a workaround to always provide far away min and max dates for now but may attempt to PR a fix myself.
 *
 * A controlled DateInput component.
 *
 *
 * Essentially, the component is really just a wrapper to integrate the Input component with [react-datepicker](https://https://reactdatepicker.com/).  Most properties of `react-datepicker` will therefore be available
 * excluding a few which are overridden to make this component easier to work with.  You can look at the component typing to see which properties are overridden.
 * Custom styling of the date picker overlay is possible via CSS overrides.
 *
 * ### Example - As Controlled DateInput
 *
 * ```tsx
 * const UseStateExample = () => {
 *   const [date, setDate] = useState('2020-01-01');
 *   return (
 *     <DateInput
 *       name="date"
 *       value={value}
 *       label="Start Date"
 *       placeholder="YYYY-MM-DD"
 *       onChange={(dateStr) => setDate(dateStr)}
 *     />
 *   );
 * };
 * ```
 *
 * ### Example - React Hook Form
 *
 * An example which uses `react-hook-form` as the controller of state.
 *
 * ```tsx
 * import { useForm, Controller } from 'react-hook-form';
 *
 * const HookFormExample = ({
 *   onSubmit
 * }: {
 *   onSubmit: (formValues) => void;
 * }) => {
 *   const { control, handleSubmit } = useForm({
 *     defaultValues: { date: '2020-01-01' }
 *   });
 *   return (
 *     <form onSubmit={handleSubmit(onSubmit)}>
 *       <Controller
 *         name='date'
 *         control={control}
 *         as={<DateInput label='Date' placeholder="YYYY-MM-DD" />}
 *       />
 *       <Button type='submit'>Submit</Button>
 *    </form>
 *   );
 * };
 * ```
 *
 */

export const DateInput: FunctionComponent<DateInputProps> = ({
  dateFormat = 'yyyy-MM-dd',
  disabled,
  inputProps,
  value = '',
  onChange,
  onSelect,
  id,
  isCustomHeader,
  ...datePickerProps
}) => {
  const maxDate = validDate(datePickerProps.maxDate, dateFormat) || addYears(new Date(), 1000);
  const minDate = validDate(datePickerProps.minDate, dateFormat) || addYears(new Date(), -1000);

  // Wrapper needed to prevent incorrect positioning caused by validation messages,labels etc.
  const PickerWrapper: FunctionComponent<{
    input?: ReactElement<unknown, string | JSXElementConstructor<any>> | undefined;
  }> = ({ input }) => {
    const customDatePickerProps = isCustomHeader ? useCustomDatePickerProps({ maxDate }) : {};
    const areDateRangeCalendars = datePickerProps?.selectsStart || datePickerProps?.selectsEnd;

    return (
      <DatePicker
        {...(datePickerProps as any)}
        dateFormat={dateFormat}
        closeOnScroll
        maxDate={maxDate}
        minDate={minDate}
        // Hot fix for React date-picker date issue: https://stackoverflow.com/questions/63284785/react-datepicker-remove-coloring-of-same-day-in-other-months
        disabledKeyboardNavigation
        customInput={input}
        disabled={disabled}
        {...(onSelect ? { onSelect: ((date: Date) => onSelect(formatDate(date, dateFormat))) as any } : {})}
        onChange={
          areDateRangeCalendars
            ? (((date: Date) => {
                const resDate = {
                  date: date,
                  formatted: formatDate(date, dateFormat),
                };

                onChange &&
                  onChange({
                    ...(datePickerProps?.selectsStart && {
                      startDate: resDate,
                    }),
                    ...(datePickerProps?.selectsEnd && {
                      endDate: resDate,
                    }),
                  });
              }) as any)
            : (date: Date) => {
                onChange && onChange(formatDate(date, dateFormat));
              }
        }
        {...customDatePickerProps}
        calendarStartDay={1}
      />
    );
  };

  return inputProps ? (
    <Input
      {...inputProps}
      className="react-datepicker-input"
      autoComplete="off"
      name={datePickerProps.name}
      id={id}
      disabled={disabled}
      value={value}
      {...(onChange
        ? {
            onChange: (e) => {
              // Prevent datepicker on change from being called with invalid values which can cause exception
              // This also allows user to directly enter date values if preferred.
              e.target.value.length !== dateFormat.length && e.stopPropagation();

              onChange(e.target.value);
            },
          }
        : {})}
      iconRight={<Icon variant="Calendar" />}
      inputWrapper={PickerWrapper}
    />
  ) : (
    <div data-testid="datepicker">
      <PickerWrapper />
    </div>
  );
};
