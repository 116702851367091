import React, { FC, ReactNode, useState } from 'react';
import { Accordion, AccordionHeader, AccordionContent } from '../../../shared/Accordion';
import { Form, FormControl, FormFieldType } from '../../../shared/Form';
import { FormControlSpacing } from '../../../shared/FormControlSpacing';
import { Button } from '../../../shared/Button';
import { useSubmitUserAnswersToAssignedQuestionnaireMutation } from '../../../../libs/graphql/baseAppAPI/mutations/__generated__/questionnaire-submit.mutation.generated';
import {
  useQuestionnaireTemplatesByIdQuery,
  QuestionnaireTemplatesByIdQuery,
} from '../../../../libs/graphql/baseAppAPI/queries/__generated__/questionnaires-get.query.generated';
import { Spinner } from '../../../shared/Spinner';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';
import { useSnackbar } from '../../../../hooks/snackbar';
import { xTenantId } from '../../../../consts';
import { Endpoint } from '../../../../models/api.model';
import { useUserContext } from '../../../../providers/UserProvider';

type AnswerChoice = {
  text: string | null | undefined;
};

type Question = {
  text: string | null | undefined;
  isOptional: boolean | null | undefined;
  type: string | null | undefined;
  answerChoices: AnswerChoice[] | null | undefined;
};

export const Questionnaire: FC<{
  questionnaireTemplatesByIdId: string;
  id: string;
  onSubmittedSuccessfully: (id: string) => void;
}> = ({ questionnaireTemplatesByIdId, id, onSubmittedSuccessfully }) => {
  const { userProfileId } = useUserContext();
  const { t } = useTranslations();
  const { addSnack } = useSnackbar();
  const [questionnaireData, setQuestionnaireData] = useState<null | QuestionnaireTemplatesByIdQuery>(null);

  const { loading } = useQuestionnaireTemplatesByIdQuery({
    variables: {
      xTenantId,
      questionnaireTemplatesByIdId,
    },
    onCompleted: (data) => {
      setQuestionnaireData(data);
    },
    onError: (error) => {
      addSnack({
        type: 'error',
        theme: 'dark',
        message: t('errors.general'),
      });
    },
    context: { endpoint: Endpoint.appAPI },
    fetchPolicy: 'network-only',
  });

  const [submitAnswers, { loading: submitting }] = useSubmitUserAnswersToAssignedQuestionnaireMutation();

  const defaultValues: Record<string, unknown> = {};

  if (loading || submitting) return <Spinner />;

  if (!questionnaireData) return null;

  const { name, questions } = questionnaireData.questionnaireTemplatesById;

  const handleSubmit = async (formData: Record<string, unknown>): Promise<void> => {
    try {
      const questionsAndAnswers = questions?.map((question, index) => {
        let answer: unknown;

        if (questionnaireTemplatesByIdId === 'ad304e4f-f428-40f1-8c34-408e70e1760b') {
          if (index === 3) answer = 'Not Selected';
          else if (index === 4 || index === 5) answer = 'ZZ';
          else answer = formData[`question_${index}`];
        } else {
          answer = formData[`question_${index}`];
        }

        return {
          answers: Array.isArray(answer) ? answer : [answer as string],
          isOptional: question?.isOptional,
          text: question?.text,
          type: question?.type,
        };
      });

      await submitAnswers({
        variables: {
          xTenantId,
          questionnaireId: id,
          userProfileId: userProfileId,
          submitUserAnswersToAssignedQuestionnaireUserInfo: {
            questionsAndAnswers,
          },
        },
      });
      addSnack({ type: 'success', message: t('questionnaire.submitSuccess') });
      onSubmittedSuccessfully(id);
    } catch (error) {
      addSnack({ type: 'error', message: t('errors.general') });
    }
  };

  const renderFormControl = (question: Question, index: number): ReactNode => {
    if (
      questionnaireTemplatesByIdId === 'ad304e4f-f428-40f1-8c34-408e70e1760b' &&
      (index === 3 || index === 4 || index === 5)
    ) {
      return null; // Don't render questions 4, 5, 6 for this template...
    }

    const commonProps = {
      name: `question_${index}`,
      label: '',
      rules: { required: true },
    };

    return (
      <>
        <p>{question?.text}</p>
        <FormControl
          type={FormFieldType.Select}
          {...commonProps}
          options={
            question?.answerChoices?.map((choice: AnswerChoice) => ({
              value: choice?.text || '',
              label: choice?.text || '',
            })) || []
          }
        />
      </>
    );
  };

  return (
    <Accordion>
      <AccordionHeader>{name}</AccordionHeader>
      <AccordionContent>
        {questionnaireTemplatesByIdId === 'bfaaabb5-b330-4cae-9e5d-8af594d662d1' && (
          <p>{t('questionnaire.sgOpeningStatement')}</p>
        )}
        {questionnaireTemplatesByIdId === 'ad304e4f-f428-40f1-8c34-408e70e1760b' && (
          <p>{t('questionnaire.sowOpeningStatement')}</p>
        )}
        <Form
          id="Questionnaire"
          onSubmit={handleSubmit}
          defaultValues={defaultValues}
          FormActions={({ submitDisabled }): JSX.Element => (
            <div className="questionnaire__btn-container">
              <Button type="submit" disabled={submitDisabled}>
                {t('buttons.submit.uppercase')}
              </Button>
            </div>
          )}
        >
          {questions?.map((question, index) => (
            <React.Fragment key={question?.text}>
              {renderFormControl(question as Question, index)}
              <FormControlSpacing />
            </React.Fragment>
          ))}
        </Form>
      </AccordionContent>
    </Accordion>
  );
};
