import { CarouselProvider, Dot } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import React, { cloneElement, FC, ReactElement, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { CarouselSliderProps, SliderWrapper } from './SliderWrapper';
import { useDeviceInfo } from '../../../hooks/useDeviceInfo';
import { useGameSlidesCount } from '../../../hooks/games';
import { passiveOption } from '../../../consts';
import { joinStrings } from '../../../utils/string';
import { Heading } from '../../shared/Heading';
import './Carousel.scss';
import { Button } from '../../shared/Button';
import { Icon } from '../../shared/Icon';
import { selectSelectedCategory } from '../../../state/app';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedCategory } from '../../../state/app/reducers';

export type CarouselAlignment = 'center' | 'top' | 'bottom';
export type CarouselZoomBehavior = 'onClick' | 'onHover';
export type CarouselContentAlignment = 'center' | 'left' | 'right';
export type CarouselOffsetStyle = 'leftRight' | 'right';

export type CarouselSharedProps = {
  /**
   * Aligns the content of the slides in the carousal.  Only relevant if slide content has varying heights.
   */
  alignment?: CarouselAlignment;
  /**
   * Aligns the inner content of the slides in the carousal.
   */
  contentAlignment?: CarouselContentAlignment;
  /**
   * Will begin cycling through the carousal at a rate determined by the autoplayInterval.
   */
  autoplay?: boolean;
  /**
   * Time, in milliseconds, before the slide progresses to the next (i.e. 1 second = 1000 ms).
   * Only applicable is autoplay is "true".*
   */
  autoplayInterval?: number;
  /**
   * Show the navigation arrows that appear on the sides of the carousal.
   * Will override to "false" for mobile devices.*
   */
  showArrows?: boolean;
  /**
   * Show the navigation dots that are in the top right corner.
   */
  showDots?: boolean;
  /**
   * Optional. Dots pagination scroll by row
   */
  paginateByRow?: boolean;
  /**
   * Creates a preview for cards on the edge.
   * For example, a value of 10 will shift the contents of the slide 10% to the left.
   */
  offsetPercentage?: number;
  /**
   * Sets which edge the preview is on, defaults to right.
   */
  offsetStyle?: CarouselOffsetStyle;
  /**
   * Determines how many slides move when clicking on next/back buttons.
   */
  step?: number;
  /**
   * Shows a title (if filled in).
   */
  title?: string;
  /**
   * Shows a subtitle (if filled in).
   */
  subtitle?: string;
  /**
   * Set to true to allow touch actions on desktop.  *Will override to "true" for mobile devices.*
   */
  touchEnabled?: boolean;
  /**
   * On touch-specific devices, how long you have to touch/press to activate an onClick event.
   */
  touchThreshold?: number;
  /**
   * Set to true to allow drag actions on desktop (basically touch for desktop).
   */
  dragEnabled?: boolean;
  /**
   * If true, disables slide content from being interacted with.
   */
  disableSlideContent?: boolean;
  /**
   * Zoom on element when hovering/clicking over a slide.
   */
  zoom?: boolean;
  /**
   * Whether the user is able to rotate the slides by prev/next buttons
   */
  infinite?: boolean;
  /**
   * Only applicable if zoom is true.  Determines which behavior triggers the zoom.
   */
  zoomBehavior?: CarouselZoomBehavior;
  /**
   * Content that will be displayed by the carousal.  Accepts components of any kind.
   */
  slides?: React.ReactNode[];
  /**
   * Event that is triggered when the user clicks on a slide.  Returns the slide index as the first parameters.
   */
  onClick?: (index: number) => void;
  /**
   * Event that is triggered after the animation has ended after clicking the next button.
   */
  onNext?: (index: number) => void;
  /**
   * Event that is triggered after the animation has ended after clicking the back button.
   */
  onBack?: (index: number) => void;
  /**
   *  Optional. Allows for custom components to be rendered in place of SliderWrapper
   */
  customSlideWrapper?: FC<CarouselSliderProps>;
};

interface CarouselProps extends CarouselSharedProps {
  /**
   * Will adjust visible slide configuration on resize event.
   */
  adjustSlidesOnResize?: boolean;
  /**
   * Will render a custom title if there're no availbale games.
   */
  emptyTitle?: string;
  /**
   * Number of slides drawn in the carousel per size configuration.  Breakpoints are set in AppConfig.
   */
  visibleConfig?: {
    wide: number;
    desktop: number;
    tablet: number;
    mobile: number;
  };
  allowGridMode?: boolean;
}

export const Carousel: FC<CarouselProps> = ({
  slides = [],
  emptyTitle = 'carousel empty',
  alignment = 'center',
  contentAlignment = 'center',
  touchEnabled = false,
  disableSlideContent = false,
  dragEnabled = false,
  showArrows = false,
  showDots = false,
  autoplay = false,
  zoom = false,
  adjustSlidesOnResize = false,
  infinite = false,
  zoomBehavior = 'onHover',
  autoplayInterval = 5000,
  offsetPercentage = 0,
  offsetStyle = 'right',
  touchThreshold = 300,
  visibleConfig = {
    wide: 4,
    desktop: 3,
    tablet: 3,
    mobile: 1,
  },
  step = 1,
  title,
  subtitle,
  onClick,
  onNext,
  onBack,
  customSlideWrapper,
  paginateByRow = false,
  allowGridMode = false,
}) => {
  const [active, setActive] = useState(false);
  const isMobileDevice = useDeviceInfo().isMobileDevice;
  const ref = useRef<HTMLDivElement>(null);
  const isPromotionPage = false;
  const dispatch = useDispatch();

  const visibleSlidesNumber = useGameSlidesCount(visibleConfig);

  const [visibleSlides, setVisibleSlides] = useState(visibleSlidesNumber);

  const [gridMode, setGridMode] = useState(false);
  const selectedCategory = useSelector(selectSelectedCategory);
  const [originalSlides] = useState(slides); // Store original slides on initial render
  const [modifiedSlides, setModifiedSlides] = useState(slides);

  useEffect(() => {
    if (allowGridMode && selectedCategory) {
      // If this carousel's title matches the selected category, show grid mode
      if (selectedCategory === title) {
        setGridMode(true);
      } else {
        // If a different category was selected, exit grid mode
        setGridMode(false);
      }
    } else if (allowGridMode && !selectedCategory) {
      // If no category is selected, exit grid mode
      setGridMode(false);
    }
  }, [selectedCategory, title, allowGridMode]);

  useLayoutEffect(() => {
    setVisibleSlides(visibleSlidesNumber);

    /* istanbul ignore next */
    const resize = (): void => {
      setVisibleSlides(visibleSlidesNumber);
    };

    if (adjustSlidesOnResize) {
      window.addEventListener('resize', resize, passiveOption);
    }

    return () => {
      window.removeEventListener('resize', resize, passiveOption as EventListenerOptions);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adjustSlidesOnResize]);

  const activeHandler = (state: boolean): void => {
    setActive(state);
  };

  const SliderWrapperComp = customSlideWrapper || SliderWrapper;
  const numberOfDots = paginateByRow ? Math.ceil(slides.length / visibleSlidesNumber) : slides.length;
  const dots = Array.from(Array(numberOfDots).keys());

  const toggleMode = (): void => {
    if (!gridMode) {
      dispatch(setSelectedCategory(title || ''));
      // TODO: improve this ux, temp solution
      window.scrollTo(0, 0);
    } else dispatch(setSelectedCategory(''));

    setGridMode(!gridMode);
  };

  useEffect(() => {
    if (gridMode) {
      const wideSlides = slides.map((slide) => {
        const slideArr = slide as unknown as ReactElement[];
        const slideElement = slideArr[0] as ReactElement;

        if (slideElement?.props?.gameCardContainerType) {
          return cloneElement(slideElement, {
            gameCardContainerType: 'wide',
          });
        }

        return slide;
      });

      setModifiedSlides(wideSlides);
    } else {
      // Restore original slides when exiting grid mode
      setModifiedSlides(originalSlides);
    }
  }, [gridMode, slides, originalSlides]);

  const renderGridLayout = (): JSX.Element => (
    <div className="carousel-wrapper__grid">
      {modifiedSlides.map((slide, index) => (
        <div key={index} className="carousel-wrapper__grid-item" onClick={(): void => onClick?.(index)}>
          {slide}
        </div>
      ))}
    </div>
  );

  return (
    <div
      data-testid="carousel-wrapper"
      className={joinStrings([
        'carousel-wrapper',
        active && 'carousel-wrapper--active',
        (isMobileDevice || touchEnabled) && 'carousel-wrapper--touchable',
        dragEnabled && 'carousel-wrapper--draggable',
        'carousel-wrapper--resizable',
        gridMode && 'carousel-wrapper--grid-mode',
      ])}
    >
      <CarouselProvider
        naturalSlideWidth={0}
        naturalSlideHeight={0}
        isPlaying={autoplay}
        interval={autoplayInterval}
        isIntrinsicHeight
        infinite={infinite}
        touchEnabled={isMobileDevice || touchEnabled}
        dragEnabled={
          /* istanbul ignore next */
          isMobileDevice ? false : dragEnabled
        }
        visibleSlides={visibleSlides}
        totalSlides={slides.length}
        step={step}
      >
        {(title || subtitle || showDots) && (
          <div className="carousel-wrapper__header">
            <section>
              {title && (
                <Heading
                  data-testid="carousel-wrapper-title"
                  className={joinStrings([
                    'carousel-wrapper__title',
                    isPromotionPage && 'carousel-wrapper__title--promotions',
                  ])}
                  level={4}
                >
                  {title}
                </Heading>
              )}
              {subtitle && (
                <p data-testid="carousel-wrapper-subtitle" className="carousel-wrapper__subtitle">
                  {subtitle}
                </p>
              )}
              {allowGridMode && (
                <Button type="button" variant="text" onClick={toggleMode}>
                  {gridMode ? 'Back to Lobby' : 'View All'}
                  <Icon variant={gridMode ? 'ChevronLeft' : 'ChevronRight'} />
                </Button>
              )}
            </section>
            <div data-testid="carousel-dots">
              {showDots &&
                dots.map((value) => (
                  <Dot
                    data-testid="carousel-dot"
                    key={value}
                    slide={paginateByRow ? value * visibleSlidesNumber : value}
                  />
                ))}
            </div>
          </div>
        )}
        {slides.length > 0 ? (
          gridMode ? (
            renderGridLayout()
          ) : (
            <div ref={ref}>
              <SliderWrapperComp
                visibleSlides={visibleSlides}
                alignment={alignment}
                contentAlignment={contentAlignment}
                disabled={disableSlideContent}
                showArrows={!isMobileDevice && showArrows && visibleSlides < slides.length}
                showDots={showDots}
                isMobile={isMobileDevice}
                offsetPercentage={offsetPercentage}
                offsetStyle={offsetStyle}
                touchThreshold={touchThreshold}
                zoom={zoom}
                zoomBehavior={zoomBehavior}
                infinite={infinite}
                slides={modifiedSlides}
                onClick={onClick}
                onNext={onNext}
                onBack={onBack}
                activeHandler={activeHandler}
              />
            </div>
          )
        ) : (
          <div className="carousel-wrapper__empty" data-testid="carousel-empty">
            <p>{emptyTitle}</p>
          </div>
        )}
      </CarouselProvider>
    </div>
  );
};
