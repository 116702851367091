import { formatDate, addDays, subDays } from 'date-fns';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { Input } from '../Input';
import './DateRange.scss';
import { joinStrings } from '../../../utils/string';
import { DateInput } from '../DateInput/DateInput';
import { DateOnChangeProps } from '../DateInput/types';

export type DateRangePayload = {
  startDate?: Date;
  endDate?: Date;
  startDateFormatted: string | null;
  endDateFormatted: string | null;
};

export type DateRangeProps = {
  /**
   * Optional custom date format.  Formats the input as well as the object that is returned on Search.
   */
  dateFormat?: string;
  /**
   * Set the starting date for the calendar.
   */
  startingDate?: Date;
  /**
   * Set the ending date for the calendar.
   */
  endingDate?: Date;
  /**
   * Set the title for the starting date input.
   */
  startDateTitle?: string;
  /**
   * Set the title for the ending date input.
   */
  endDateTitle?: string;
  /**
   * Can set the maximum range of days that the user can select on the calendar.
   */
  maxRangeInDays?: number;
  /**
   * If true, calender will always be visible as an inline element.  Close button will not be shown.
   */
  alwaysShow?: boolean;
  /**
   * Apply a style the the dateRange wrapper.
   */
  style?: React.CSSProperties;
  /**
   * Styles the inputs with preexisting light/dark themes.  Currently does not style the calendar.
   */
  theme?: 'light' | 'dark';
  /**
   * Callback triggered when clicking on the action button.  Returns an object with currently selected starting/ending dates.
   */
  onAction?: (props: DateRangePayload) => void;
  /**
   * Callback triggered when clicking on the close button.
   */
  onClose?: () => void;
  /**
   * Display custom header with month and year select. Defaults to false.
   */
  isCustomHeader?: boolean;
  /**
   * If true, will set the warning text to be visible.
   */
  showWarningText?: boolean;
  /**
   * Optional, if use custom styles for input inside DateRange
   */
  inputBoxClassName?: string;
};

export const DateRange: FunctionComponent<DateRangeProps> = ({
  dateFormat = 'yyyy-MM-dd',
  startingDate,
  endingDate,
  startDateTitle,
  endDateTitle,
  alwaysShow = false,
  maxRangeInDays = 365,
  style,
  theme = 'light',
  onAction,
  onClose,
  isCustomHeader,
  showWarningText = false,
  inputBoxClassName,
}) => {
  const today = new Date();

  const [startDate, setStartDate] = useState<Date | undefined>(startingDate || undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(endingDate || undefined);
  const [startDateFormatted, setStartDateFormatted] = useState<string>(
    startingDate ? formatDate(startingDate, dateFormat) : ''
  );
  const [endDateFormatted, setEndDateFormatted] = useState<string>(
    endingDate ? formatDate(endingDate, dateFormat) : ''
  );
  const [showCalender, setShowCalendar] = useState(false);
  const [showStartCalendar, setShowStartCalendar] = useState(false);
  const [showEndCalendar, setShowEndCalendar] = useState(false);

  useEffect(() => {
    if (startingDate) {
      setStartDate(startingDate);
      setStartDateFormatted(formatDate(startingDate, dateFormat));
    }
  }, [startingDate, dateFormat]);

  useEffect(() => {
    if (endingDate) {
      setEndDate(endingDate);
      setEndDateFormatted(formatDate(endingDate, dateFormat));
    }
  }, [endingDate, dateFormat]);

  const onChange = (data: DateOnChangeProps | string): void => {
    const { startDate, endDate } = data as DateOnChangeProps;

    if (startDate) {
      setStartDate(startDate.date);
      setStartDateFormatted(startDate.formatted);
    }

    if (endDate) {
      setEndDate(endDate.date);
      setEndDateFormatted(endDate.formatted);
    }
  };

  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    if (ref.current && !ref.current.contains(event.target as Node) && showCalender) {
      setShowCalendar(false);
    }
  };

  const getMaxDate = (): Date | undefined => {
    const absoluteMaxDate = addDays(String(startDate), maxRangeInDays);

    return absoluteMaxDate > today ? today : absoluteMaxDate;
  };

  const getMinDate = (): Date | undefined => subDays(String(endDate), maxRangeInDays);

  return (
    <div ref={ref} className="daterange" data-testid="daterange" style={style}>
      <div className="daterange__input-container">
        <Input
          data-testid="daterange-start"
          iconLeft={<Icon variant="Calendar" />}
          labelProps={{
            labelVariant: 'floating',
          }}
          label={startDateTitle || 'Start date'}
          theme={theme}
          readOnly
          fill
          onClick={(): void => {
            setShowCalendar(true);

            setShowEndCalendar(false);
            setShowStartCalendar(true);
          }}
          value={startDateFormatted}
          inputBoxClassName={inputBoxClassName}
        />
        <Input
          data-testid="daterange-end"
          iconLeft={<Icon variant="Calendar" />}
          labelProps={{
            labelVariant: 'floating',
          }}
          label={endDateTitle || 'End date'}
          theme={theme}
          readOnly
          fill
          onClick={(): void => {
            setShowCalendar(true);

            setShowStartCalendar(false);
            setShowEndCalendar(true);
          }}
          value={endDateFormatted}
          inputBoxClassName={inputBoxClassName}
        />
      </div>

      {(showCalender || alwaysShow) && (
        <div
          data-testid="daterange-calendar"
          className={joinStrings(['daterange__calendar', alwaysShow && 'daterange__calendar--show'])}
        >
          {showStartCalendar && (
            <DateInput
              excludeScrollbar
              onClickOutside={handleClickOutside}
              dateFormat={dateFormat}
              startDate={startDate}
              endDate={endDate}
              selected={startDate}
              onChange={onChange}
              focusSelectedMonth
              inline
              isCustomHeader={isCustomHeader}
              selectsStart={showStartCalendar}
              selectsEnd={showEndCalendar}
              minDate={getMinDate()}
              maxDate={endDate}
            />
          )}

          {showEndCalendar && (
            <DateInput
              excludeScrollbar
              onClickOutside={handleClickOutside}
              dateFormat={dateFormat}
              startDate={startDate}
              endDate={endDate}
              selected={endDate}
              onChange={onChange}
              focusSelectedMonth
              inline
              isCustomHeader={isCustomHeader}
              selectsStart={showStartCalendar}
              selectsEnd={showEndCalendar}
              minDate={startDate}
              maxDate={getMaxDate()}
            />
          )}

          {showWarningText && (
            <div className="daterange__warning-text">
              <span>*Please select a date range of maximum 12 months.</span>
            </div>
          )}

          <div className="daterange__buttons">
            {!alwaysShow && (
              <Button
                data-testid="daterange-close-btn"
                type="button"
                size="auto"
                variant="secondary"
                onClick={(): void => {
                  onClose && onClose();
                  setShowCalendar(false);
                }}
              >
                CANCEL
              </Button>
            )}
            <Button
              data-testid="daterange-action-btn"
              disabled={!endDate}
              type="button"
              size="auto"
              variant="primary"
              onClick={(): void => {
                onAction &&
                  onAction({
                    startDate,
                    endDate,
                    startDateFormatted,
                    endDateFormatted,
                  });
                setShowCalendar(false);
              }}
            >
              SUBMIT
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
