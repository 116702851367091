import {
  ParsedTransactionHistoryPagingData,
  TransactionHistoryReport,
  TransactionHistoryReportData,
  TransactionHistoryActivity,
  PlayerActivitiesStatisticsData,
} from '../../../models/transactions.model';

export const getTransactionHistoryResponse = (): TransactionHistoryActivity[] => [
  {
    id: '6178ad6f-9e7d-4bbd-a5c4-c3160ca08b76',
    siteCode: 'yoursitecode',
    transactionDate: '2024-11-22T16:15:08',
    type: 'Withdrawal',
    displayType: 'Bonus Expired',
    amount: 200,
    description: 'A test transaction description to be shown in the table',
    correlationToken: '14fb13cf-83de-40eb-bda6-62b455ed2625',
    totalBalanceAfter: 9465.9,
    cashBalanceAfter: 9046.9,
    effects: [
      {
        walletType: 'BonusVertical',
        walletName: 'Casino',
        walletDisplayName: 'Bonus-Casino',
        compartment: 'Adjustments',
        promoCode: 'Summer3',
        amount: -200,
        balanceBefore: { adjustments: 200, winnings: 0, ringfence: 0 },
        balanceAfter: { adjustments: 0, winnings: 0, ringfence: 0 },
      },
    ],
    details: {
      category: 'Banking',
      bonusName: 'Summer3',
      promoCode: 'Summer3',
      currency: 'CAD',
    },
    playerId: '10009780',
    betting: {
      betActions: [{ remoteBetId: '4353435227' }],
    },
  },
  {
    id: '6178ad6f-9e7d-4bbd-a5c4-c3160ca08b76',
    siteCode: 'yoursitecode',
    transactionDate: '2024-11-21T16:15:08',
    type: 'Withdrawal',
    displayType: 'Bonus Simple Win',
    amount: 200,
    description: 'A test transaction description to be shown in the table',
    correlationToken: '14fb13cf-83de-40eb-bda6-62b455ed2625',
    totalBalanceAfter: 9465.9,
    cashBalanceAfter: 9046.9,
    effects: [
      {
        walletType: 'BonusVertical',
        walletName: 'Casino',
        walletDisplayName: 'Bonus-Casino',
        compartment: 'Adjustments',
        promoCode: 'Summer3',
        amount: -200,
        balanceBefore: { adjustments: 200, winnings: 0, ringfence: 0 },
        balanceAfter: { adjustments: 0, winnings: 0, ringfence: 0 },
      },
    ],
    details: {
      category: 'Betting',
      bonusName: 'Summer3',
      promoCode: 'Summer3',
      currency: 'CAD',
    },
    playerId: '10009780',
    betting: {
      betActions: [{ remoteBetId: '4353435227' }],
    },
  },
  {
    id: 'c9c2d902-0134-4e62-ae93-3efa8b873b1e',
    siteCode: 'yoursitecode',
    transactionDate: '2024-11-20T15:14:35',
    type: 'Deposit',
    displayType: 'Deposit - Completed',
    amount: 200,
    description: 'A Test Translation key',
    correlationToken: 'd0f7488e-c807-4be9-8888-1f937e1e8f5f',
    totalBalanceAfter: 9665.9,
    cashBalanceAfter: 9046.9,
    effects: [
      {
        walletType: 'Cash',
        walletName: 'NonCredit',
        walletDisplayName: 'Cash-NonCredit',
        compartment: 'Adjustments',
        amount: 200,
        balanceBefore: { adjustments: 332, winnings: 200.4, ringfence: 0 },
        balanceAfter: { adjustments: 532, winnings: 200.4, ringfence: 0 },
      },
    ],
    details: {
      category: 'Banking',
      subType: 'Completed',
      currency: 'CAD',
      providerName: 'PaySafe',
      paymentEntityDetails: 'Interac ',
    },
    playerId: '10009780',
    remoteReference: '3yB8HMRa2HeliwBe09Df',
    betting: {
      betActions: [{ remoteBetId: '4353434523' }],
    },
  },
  {
    id: 'd99b8c22-32a2-4009-8915-85e4dc7212aa',
    siteCode: 'yoursitecode',
    transactionDate: '2024-11-19T15:14:06',
    type: 'BonusActivated',
    displayType: 'Bonus Activated',
    amount: 200,
    description: 'Summer3',
    correlationToken: '65923554-f84a-434b-a133-aa968c7d1b5e',
    totalBalanceAfter: 9465.9,
    cashBalanceAfter: 8846.9,
    effects: [
      {
        walletType: 'BonusVertical',
        walletName: 'Casino',
        walletDisplayName: 'Bonus-Casino',
        compartment: 'Adjustments',
        promoCode: 'Summer3',
        amount: 200,
        balanceBefore: { adjustments: 0, winnings: 0, ringfence: 0 },
        balanceAfter: { adjustments: 200, winnings: 0, ringfence: 0 },
      },
    ],
    details: {
      category: 'Bonus',
      bonusName: 'Summer3',
      promoCode: 'Summer3',
      currency: 'CAD',
    },
    playerId: '10009780',
    betting: {
      betActions: [{ remoteBetId: '4353434522' }],
    },
  },
  {
    id: '49b24f3f-2013-4d14-b952-ccc9f042d0fa',
    siteCode: 'yoursitecode',
    transactionDate: '2024-12-12T15:14:05',
    type: 'BonusCompleted',
    displayType: 'Bonus Completed',
    amount: 20,
    description: 'QA Regression Deposit Over Period',
    correlationToken: '65923554-f84a-434b-a133-aa968c7d1b5e',
    totalBalanceAfter: 9265.9,
    cashBalanceAfter: 8846.9,
    effects: [
      {
        walletType: 'Cash',
        walletName: 'NonCredit',
        walletDisplayName: 'Cash-NonCredit',
        compartment: 'Adjustments',
        amount: 20,
        balanceBefore: { adjustments: 312, winnings: 200.4, ringfence: 0 },
        balanceAfter: { adjustments: 332, winnings: 200.4, ringfence: 0 },
      },
    ],
    details: {
      category: 'Bonus',
      bonusName: 'QA Regression Deposit Over Period',
      promoCode: 'QARegressionDepositOverPeriod',
      currency: 'CAD',
    },
    playerId: '10009780',
    betting: {
      betActions: [{ remoteBetId: '4353434521' }],
    },
  },
  {
    id: '49b24f3f-2013-4d14-b952-ccc9f042d0fa',
    siteCode: 'yoursitecode',
    transactionDate: '2024-12-09T15:14:05',
    type: 'BonusCompleted',
    displayType: 'Bonus Completed',
    amount: 20,
    description: 'QA Regression Deposit Over Period',
    correlationToken: '65923554-f84a-434b-a133-aa968c7d1b5e',
    totalBalanceAfter: 9265.9,
    cashBalanceAfter: 8846.9,
    effects: [
      {
        walletType: 'Cash',
        walletName: 'NonCredit',
        walletDisplayName: 'Cash-NonCredit',
        compartment: 'Adjustments',
        amount: 20,
        balanceBefore: { adjustments: 312, winnings: 200.4, ringfence: 0 },
        balanceAfter: { adjustments: 332, winnings: 200.4, ringfence: 0 },
      },
    ],
    details: {
      category: 'Bonus',
      bonusName: 'QA Regression Deposit Over Period',
      promoCode: 'QARegressionDepositOverPeriod',
      currency: 'CAD',
    },
    playerId: '10009780',
    betting: {
      betActions: [{ remoteBetId: '4353434522' }],
    },
  },
  {
    id: 'c3db0cf8-129d-46e9-8657-a94aec3025aa',
    siteCode: 'yoursitecode',
    transactionDate: '2024-12-31T15:14:05',
    type: 'Deposit',
    displayType: 'Deposit - Completed',
    amount: 200,
    description: 'Interac ',
    correlationToken: '65923554-f84a-434b-a133-aa968c7d1b5e',
    totalBalanceAfter: 9245.9,
    cashBalanceAfter: 8826.9,
    effects: [
      {
        walletType: 'Cash',
        walletName: 'NonCredit',
        walletDisplayName: 'Cash-NonCredit',
        compartment: 'Adjustments',
        amount: 200,
        balanceBefore: { adjustments: 112, winnings: 200.4, ringfence: 0 },
        balanceAfter: { adjustments: 312, winnings: 200.4, ringfence: 0 },
      },
    ],
    details: {
      category: 'Banking',
      subType: 'Completed',
      currency: 'CAD',
      providerName: 'PaySafe',
      paymentEntityDetails: 'Interac ',
    },
    playerId: '10009780',
    remoteReference: 'sMcqhdhtCFHX410LghK6',
    betting: {
      betActions: [{ remoteBetId: '4353434524' }],
    },
  },
  {
    id: '3dee82e7-25a2-499a-ac5d-d123c176c54b',
    siteCode: 'yoursitecode',
    transactionDate: '2025-01-08T15:13:55',
    type: 'Deposit',
    displayType: 'Deposit - Confirmed',
    amount: 200,
    description: 'Interac ',
    correlationToken: 'd0f7488e-c807-4be9-8888-1f937e1e8f5f',
    effects: [],
    details: {
      category: 'Banking',
      subType: 'Authorized',
      currency: 'CAD',
      providerName: 'PaySafe',
      paymentEntityDetails: 'Interac ',
    },
    playerId: '10009780',
    remoteReference: '3yB8HMRa2HeliwBe09Df',
    betting: {
      betActions: [{ remoteBetId: '4353434588' }],
    },
  },
  {
    id: '734256d1-f6f6-4e52-9ed5-cb6124ba5ab5',
    siteCode: 'yoursitecode',
    transactionDate: '2024-10-30T15:13:25',
    type: 'Deposit',
    displayType: 'Deposit - Authorized',
    amount: 200,
    description: 'Interac ',
    correlationToken: '65923554-f84a-434b-a133-aa968c7d1b5e',
    effects: [],
    details: {
      category: 'Banking',
      subType: 'Authorized',
      currency: 'CAD',
      providerName: 'PaySafe',
      paymentEntityDetails: 'Interac ',
    },
    playerId: '10009780',
    remoteReference: 'sMcqhdhtCFHX410LghK6',
  },
  {
    id: '866bb09b-4e54-4259-a07d-8f2f12240dcf',
    siteCode: 'yoursitecode',
    transactionDate: '2024-11-30T23:30:01',
    type: 'BonusExpired',
    displayType: 'Bonus Expired',
    amount: 5,
    description: 'TagDeposit',
    correlationToken: 'c0d830da-e7be-4cca-a716-e65408b931f7',
    totalBalanceAfter: 9045.9,
    cashBalanceAfter: 8626.9,
    effects: [
      {
        walletType: 'BonusTagGroup',
        walletName: 'TagDeposit',
        walletDisplayName: 'BonusTagGroup-TagDeposit',
        compartment: 'Adjustments',
        promoCode: 'TagDeposit',
        amount: -5,
        balanceBefore: { adjustments: 5, winnings: 0, ringfence: 0 },
        balanceAfter: { adjustments: 0, winnings: 0, ringfence: 0 },
      },
    ],
    details: {
      category: 'Bonus',
      bonusName: 'TagDeposit',
      promoCode: 'TagDeposit',
      currency: 'CAD',
    },
    playerId: '10009780',
  },
  {
    id: '4a2705c9-b9d7-46f8-bd5b-aa97f4e36ee9',
    siteCode: 'yoursitecode',
    transactionDate: '2024-12-10T09:31:01',
    type: 'Subscription',
    displayType: 'Subscription',
    amount: 5,
    description: 'A description containing credit number 4111************',
    correlationToken: '4376c8af-1daa-4296-b752-e4fb67243f74',
    totalBalanceAfter: 9050.9,
    cashBalanceAfter: 8626.9,
    effects: [
      {
        walletType: 'BonusVertical',
        walletName: 'Lottery',
        walletDisplayName: 'Bonus-Lottery',
        compartment: 'Adjustments',
        amount: -5,
        balanceBefore: { adjustments: 14, winnings: 0, ringfence: 0 },
        balanceAfter: { adjustments: 9, winnings: 0, ringfence: 0 },
      },
    ],
    details: {
      category: 'Lottery',
      bedeTicketId: 625521617,
      subscriptionId: 1013330,
      currency: 'CAD',
      lotteryActions: [
        {
          bedeActionId: 3399026,
          gameCode: 'LGAME',
          gameName: 'Lottery Game',
          amount: 5,
        },
      ],
    },
    playerId: '10009780',
  },
  {
    id: '2ff57063-a2e9-4325-ae5a-dbe30b3362c4',
    siteCode: 'yoursitecode',
    transactionDate: '2025-02-15T09:26:15',
    type: 'Purchase',
    displayType: 'Purchase',
    amount: 5,
    description: 'A very long description going over the max length that transaction descriptions are accepted to be',
    correlationToken: '268bf751-4f7d-4f97-8efc-7d67f35ad1e4',
    totalBalanceAfter: 9055.9,
    cashBalanceAfter: 8626.9,
    effects: [
      {
        walletType: 'BonusVertical',
        walletName: 'Lottery',
        walletDisplayName: 'Bonus-Lottery',
        compartment: 'Adjustments',
        amount: -5,
        balanceBefore: { adjustments: 19, winnings: 0, ringfence: 0 },
        balanceAfter: { adjustments: 14, winnings: 0, ringfence: 0 },
      },
    ],
    details: {
      category: 'Lottery',
      bedeTicketId: 625521615,
      currency: 'CAD',
      lotteryActions: [
        {
          bedeActionId: 3399024,
          gameCode: 'LGAME',
          gameName: 'Lottery Game',
          amount: 5,
        },
      ],
    },
    playerId: '10009780',
  },
];

export const getTransactionHistoryPagingResponse = (): ParsedTransactionHistoryPagingData => ({
  itemsPerPage: 5,
  totalItems: 200,
  totalPages: 20,
});

export const getShortTransactionHistoryResponse = (): TransactionHistoryActivity[] => [
  {
    id: '6178ad6f-9e7d-4bbd-a5c4-c316asdab76',
    siteCode: 'yoursitecode',
    transactionDate: '2020-11-25T11:15:08',
    type: 'BonusExpired',
    displayType: 'Pending',
    amount: 200,
    description: 'A bonus that has expired',
    correlationToken: '14fb13fg-83de-40eb-bda6-62b455ed2625',
    totalBalanceAfter: 3783.9,
    cashBalanceAfter: 8598.9,
    effects: [
      {
        walletType: 'BonusVertical',
        walletName: 'Casino',
        walletDisplayName: 'Bonus-Casino',
        compartment: 'Adjustments',
        promoCode: 'BONUS1',
        amount: -153.56,
        balanceBefore: { adjustments: 153.56, winnings: 0, ringfence: 0 },
        balanceAfter: { adjustments: 0, winnings: 0, ringfence: 0 },
      },
    ],
    details: {
      category: 'Bonus',
      bonusName: 'BONUS1',
      promoCode: 'BONUS1',
      currency: 'CAD',
    },
    playerId: '10009780',
  },
  {
    id: '61asdaad6f-9e7d-4bbd-a5c4-c3160ca08b76',
    siteCode: 'yoursitecode',
    transactionDate: '2020-11-28T16:30:08',
    type: 'BonusExpired',
    displayType: 'Pending',
    amount: 200,
    description: 'A very unique transaction description',
    correlationToken: '14fb13cf-83de-40eb-bda6-62b455ed2625',
    totalBalanceAfter: 8231,
    cashBalanceAfter: 8220.1,
    effects: [
      {
        walletType: 'BonusVertical',
        walletName: 'Casino',
        walletDisplayName: 'Bonus-Casino',
        compartment: 'Adjustments',
        promoCode: 'BONUS2',
        amount: -110.99,
        balanceBefore: { adjustments: 110.99, winnings: 0, ringfence: 0 },
        balanceAfter: { adjustments: 0, winnings: 0, ringfence: 0 },
      },
    ],
    details: {
      category: 'Bonus',
      bonusName: 'BONUS2',
      promoCode: 'BONUS2',
      currency: 'CAD',
    },
    playerId: '10009780',
  },
];

export const getMultipleTransactionHistorys = (): TransactionHistoryReport[] => [
  {
    bedePlayerId: '654',
    completedOn: '2021-10-08T13:49:33.33',
    endDate: '2021-10-08T13:49:33.1466667',
    fileName: 'adam.foy@bedegaming.com-TRANSACTIONHISTORY-REPORT-2021-10-08T13-49-33.csv',
    format: 'csv',
    id: '32',
    requestedOn: '2021-10-08T13:49:33.1466667',
    startDate: '2020-10-08T13:49:33.1466667',
    status: 'Completed',
  },
  {
    bedePlayerId: '654',
    completedOn: '2021-10-08T13:55:33.33',
    endDate: '2021-10-08T13:55:33.1466667',
    fileName: 'adam.foy@bedegaming.com-TRANSACTIONHISTORY-REPORT-2021-10-08T13-49-35.csv',
    format: 'csv',
    id: '33',
    requestedOn: '2021-10-08T13:55:33.1466667',
    startDate: '2020-10-08T13:55:33.1466667',
    status: 'Completed',
  },
];

export const getTransactionHistoryReportAlt = (): TransactionHistoryReportData => ({
  items: getMultipleTransactionHistorys(),
});

export const getTransactionHistoryResponseById = (): TransactionHistoryReport => ({
  bedePlayerId: '654',
  completedOn: '2021-10-08T13:49:33.33',
  endDate: '2021-10-08T13:49:33.1466667',
  fileName: 'adam.foy@bedegaming.com-TRANSACTIONHISTORY-REPORT-2021-10-08T13-49-33.csv',
  format: 'csv',
  id: '32',
  requestedOn: '2021-10-08T13:49:33.1466667',
  startDate: '2020-10-08T13:49:33.1466667',
  status: 'Completed',
});

export const getShortTransactionHistoryPagingResponse = (): ParsedTransactionHistoryPagingData => ({
  itemsPerPage: 10,
  totalItems: 2,
  totalPages: 1,
});

export const getDownloadBlob = (): boolean => {
  return true;
};

export const getActivityStatistics = (): PlayerActivitiesStatisticsData => ({
  transactionsCount: 100,
  banking: {
    depositsCount: 50,
    depositsAmount: 950,
    withdrawalsCount: 12,
    withdrawalsAmount: 2500,
  },
  reward: {
    rewardCreated: 3,
    rewardExpired: 10,
    rewardCancelled: 2,
  },
  loyalty: {
    pointsAccrued: 100,
    pointsRedeemed: 30,
    pointsAdjustments: 145,
  },
  gaming: {
    games: [
      {
        name: 'Perfect Game',
        tipsCount: 12,
        winsCount: 3,
        winsAmount: 23,
        tipsAmount: 103,
        stakesCount: 8,
        stakesAmount: 74,
      },
    ],
    totals: {
      winsCount: 32,
      tipsCount: 300,
      tipsAmount: 433,
      winsAmount: 23,
      stakesCount: 12,
      stakesAmount: 234,
    },
  },
  bonus: {
    totals: {
      bonusActivatedCount: 30,
      bonusActivatedAmount: 30,
      bonusCancelledCount: 30,
      bonusCancelledAmount: 30,
      bonusCompletedCount: 30,
      bonusCompletedAmount: 30,
      bonusExpiredCount: 30,
      bonusExpiredAmount: 30,
      bonusFulfilledCount: 30,
      bonusFulfilledAmount: 30,
      bonusRevokedCount: 30,
      bonusRevokedAmount: 30,
    },
    effects: [
      {
        amount: 10,
        walletType: 'bonus',
        walletName: 'Promo Wallet',
      },
    ],
  },
});
