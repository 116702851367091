import React, { FC, useState, useEffect } from 'react';
import { xTenantId } from '../../../../consts';
import { useUserContext } from '../../../../providers/UserProvider';
import {
  UserQuestionnairesQuery,
  useUserQuestionnairesQuery,
} from '../../../../libs/graphql/baseAppAPI/queries/__generated__/user-questionnaires.query.generated';
import { useSnackbar } from '../../../../hooks/snackbar';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';
import { Spinner } from '../../../shared/Spinner';
import { Endpoint } from '../../../../models/api.model';
import './Questionnaires.scss';
import { Questionnaire } from './Questionnaire';
import { AccountTile } from '../AccountTile';
import { Prompt } from '../../../shared/Prompt';
import { PopUpHandler } from '../../../shared/PopupHandler';
import { useAuth } from 'react-oidc-context';

const POST_LOGIN_CHECK_KEY = 'questionnaire_post_login_check';

export const QuestionnairesList: FC = () => {
  const auth = useAuth();
  const isAuthenticated = auth?.isAuthenticated;
  const { userProfileId } = useUserContext();
  const { addSnack } = useSnackbar();
  const { t } = useTranslations();
  const [questionnairesData, setQuestionnairesData] = useState<null | UserQuestionnairesQuery>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [promptAcknowledged, setPromptAcknowledged] = useState(false);
  const [shouldCheckQuestionnaires, setShouldCheckQuestionnaires] = useState(false);
  const [questionnaires, setQuestionnaires] = useState<UserQuestionnairesQuery['userQuestionnaires']['data']>([]);

  useEffect(() => {
    if (questionnairesData?.userQuestionnaires.data) {
      const pendingQuestionnaires = questionnairesData.userQuestionnaires.data.filter(
        (q11e) => q11e?.status === 'Requested'
      );

      setQuestionnaires(pendingQuestionnaires);
      // Only show content if prompt was acknowledged and we have questionnaires
      setIsOpen(promptAcknowledged && pendingQuestionnaires.length > 0);
    }
  }, [questionnairesData, promptAcknowledged]);

  const handleQuestionnaireSubmitted = (id: string): void => {
    setQuestionnaires((prevQuestionnaires) => {
      const updatedQuestionnaires = prevQuestionnaires?.filter((q11e) => q11e?.id !== id) ?? [];

      if (updatedQuestionnaires.length === 0) {
        setIsOpen(false);
      }

      return updatedQuestionnaires;
    });
  };

  useEffect(() => {
    if (!isAuthenticated) {
      sessionStorage.removeItem(POST_LOGIN_CHECK_KEY);

      return;
    }

    const hasCheckedPostLogin = sessionStorage.getItem(POST_LOGIN_CHECK_KEY);

    if (!hasCheckedPostLogin) {
      setShouldCheckQuestionnaires(true);
    }
  }, [isAuthenticated]);

  const { loading, refetch } = useUserQuestionnairesQuery({
    variables: {
      xTenantId,
      userProfileId,
    },
    onCompleted: (data) => {
      setQuestionnairesData(data);
    },
    onError: (error) => {
      handleError();
    },
    context: { endpoint: Endpoint.appAPI },
    fetchPolicy: 'network-only',
    skip: !isAuthenticated || !shouldCheckQuestionnaires,
  });

  useEffect(() => {
    if (shouldCheckQuestionnaires && isAuthenticated) {
      refetch();
    }
  }, [shouldCheckQuestionnaires, refetch, isAuthenticated]);

  const handleError = (): void => {
    addSnack({
      type: 'error',
      theme: 'dark',
      message: t('errors.general'),
    });
    setShouldCheckQuestionnaires(false);
  };

  const handleClose = (): void => {
    setIsOpen(false);
    sessionStorage.setItem(POST_LOGIN_CHECK_KEY, 'true');
  };

  const handlePromptClose = (): void => {
    setPromptAcknowledged(true);
    setIsOpen(true);
  };

  // questionnaires is init'd as empty array
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const showPrompt = !promptAcknowledged && questionnaires.length > 0;

  if (!isAuthenticated) {
    return null;
  }

  return (
    <>
      <div className="questionnaires-list">
        <Prompt
          PrimaryButton={{ text: t('questionnaire.promptAcknowledge'), onClick: handlePromptClose }}
          show={showPrompt}
          title={t('questionnaire.heading')}
          onClose={handlePromptClose}
        >
          {t('questionnaire.promptBody')}
        </Prompt>
        <PopUpHandler
          isOpen={isOpen}
          onClose={handleClose}
          header={t('questionnaire.heading')}
          modalProps={{ 'aria-label': 'Questionnaires', className: 'questionnaires-modal' }}
          drawerProps={{ className: 'questionnaires-drawer' }}
          showCloseBtn
        >
          {loading ? (
            <div className="spinner-wrapper">
              <Spinner />
            </div>
          ) : (
            <div className="questionnaires-container">
              <AccountTile>
                <ul>
                  {questionnaires?.map((q11e) => (
                    <Questionnaire
                      key={q11e?.id as string}
                      questionnaireTemplatesByIdId={q11e?.questionnaireTemplateId as string}
                      id={q11e?.id as string}
                      onSubmittedSuccessfully={handleQuestionnaireSubmitted}
                    />
                  ))}
                </ul>
              </AccountTile>
            </div>
          )}
        </PopUpHandler>
      </div>
    </>
  );
};
