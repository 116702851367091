import { formatDate, parse } from 'date-fns';
import findLastIndex from 'lodash/findLastIndex';
import keyBy from 'lodash/keyBy';
import { useRef, useState } from 'react';
import {
  GenderOptions,
  HonoricTitleOptions,
  PlayerSession,
  RegistrationStepConfig,
} from '../../components/Registration/types';
import { FormField } from '../../components/shared/Form';
import { countryCodeOptions } from '../../components/shared/TelephoneInput/countryCodes';
import { useRegisterUserMutation } from '../../libs/graphql/baseAppAPI/mutations/__generated__/register-user.mutation.generated';
import { capitalizeFirstLetter } from '../../utils/form';
import { PhoneNumber } from '../../utils/phone-number';
import { updateFormDataByFieldConfig } from './useRegistrationStepper.helper';
import { BaseRegistrationStepConfig, FlattenedProfileData } from './useRegistrationStepper.model';
import { trackEvent } from '../../tracking/tracking';
import { RegistrationSuccessEvent } from '../../tracking/types';

const equalsStepId =
  (stepId: string) =>
  (step: BaseRegistrationStepConfig): boolean =>
    step.id === stepId;

const getGender = (honoricTitle?: HonoricTitleOptions): GenderOptions => {
  switch (honoricTitle) {
    case HonoricTitleOptions.Mr:
      return GenderOptions.Male;
    case HonoricTitleOptions.Ms:
    case HonoricTitleOptions.Mrs:
      return GenderOptions.Female;
    case HonoricTitleOptions.Others:
      return GenderOptions.Others;
    default:
      return GenderOptions.Male;
  }
};

/**
 * Get a key value map of player form field configurations.
 * @param config
 */
export type RegistrationFieldMap = Record<string, FormField<Partial<FlattenedProfileData>>>;

export type UseRegistrationStepperOptions = {
  /**
   * Initial form state for registration data
   */
  defaultFormState: FlattenedProfileData;
  /**
   * List of registration steps to be used.
   */
  registrationSteps: RegistrationStepConfig[];
  /**
   * Form field configurations
   */
  formFields: RegistrationFieldMap;
  /**
   * Optionally update request values.
   */
  showIntroduction?: boolean;
  mapRequestValues?: (formState: FlattenedProfileData) => FlattenedProfileData;
};

export type UseRegistrationStepperReturnValue = {
  /**
   * Index of currently selected step.
   */
  selectedStepIndex: number;
  /**
   * Index of previously selected step if there is one.
   */
  previouslySelectedStepIndex?: number;
  /**
   * Currently selected step configuration
   */
  selectedStep: BaseRegistrationStepConfig;
  /**
   * Step index to redirect to if user tries to manually access a disabled step route.
   */
  redirectStepIndex: number;
  /**
   * Current form state.
   * The stepper logic will merge state updates over top of default form state
   * when steps are completed or there is a request to merge partially completed data.
   * This may happen when the user navigates to a previous.
   */
  formState: FlattenedProfileData;
  /**
   * State to keep track of status of each step.
   */
  status: Record<number, { completed: boolean; stepNumber: number; enabled: boolean }>;
  /**
   * True if currently making request to register player.
   */
  isSubmitting: boolean;
  /**
   * Call this when the user has completed current step to trigger form state update and move to next step.
   * @param stepIndex
   * @param stepFormData
   */
  completeStep(stepIndex: number, stepFormData: Partial<FlattenedProfileData>): void;
  /**
   * Should be called when the user invalidates previously completed step (by deleting some data etc)
   * @param stepIndex
   */
  invalidateStep(stepIndex: number): void;
  /**
   * Go back to to previous step.  Should be called when user clicks back.
   */
  previousStep(): void;
  /**
   * Should be called to merge in partial form data to save it before navigation occurs.
   * @param partialRegistrationData
   */
  mergePartialData(partialRegistrationData: Partial<FlattenedProfileData>): void;
  /**
   * Request to set the selected step by step id.  Will only proceed if user is allowed to switch to step.
   * @param stepId
   */
  setSelectedStep(stepId: string): void;
  registrationSuccessResponse: PlayerSession;
  registrationErrors: string;
};

/**
 * Registration stepper logic hook.
 *
 * Used for registration and made available for potential use in custom registration implementations.
 * Also keeps the registration UI cleaner.
 *
 * Some of the logic could potentially be extracted into a more general stepper hook
 * if other components which depend on stepper logic are needed.
 */
export function useRegistrationStepper({
  defaultFormState,
  registrationSteps,
  formFields,
}: UseRegistrationStepperOptions): UseRegistrationStepperReturnValue {
  const [formState, setFormState] = useState<FlattenedProfileData>(defaultFormState);

  const formSubmitted = useRef(false);

  const [registrationSuccessResponse, setRegistrationSuccessResponse] = useState<PlayerSession>({
    access_token: 'token',
    expires_in: 3600,
    token_type: 'type',
    refresh_token: 'refresh',
  });
  const [registrationErrors, setRegistrationErrors] = useState<string>('');
  const [selectedStepIndex, setSelectedStepIndex] = useState(0);

  const [previouslySelectedStepIndex, setPreviouslySelectedStepIndex] = useState<number>();
  const [status, setStatus] = useState(
    keyBy(
      registrationSteps.map((_, stepNumber) => {
        return {
          stepNumber,
          completed: false,
          enabled: stepNumber === 0,
        };
      }),
      (s) => s.stepNumber
    )
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRegistrationFailed = (error?: any): void => {
    const nextStepId = 'error';
    const errorStepIndex = registrationSteps.findIndex(equalsStepId(nextStepId));

    setRegistrationErrors('reg error');
    disableOtherSteps(nextStepId);
    nextStep(errorStepIndex);
    console.error('Registration failed: ', error);
  };

  const [registerUserMutation, { loading }] = useRegisterUserMutation({
    onCompleted: (data) => {
      const successStepIndex = registrationSteps.findIndex(equalsStepId('success'));

      if (data.registerUser?.error) {
        onRegistrationFailed(data.registerUser?.error);

        return;
      }

      setRegistrationSuccessResponse({
        access_token: 'token',
        expires_in: 3600,
        token_type: 'type',
        refresh_token: 'refresh',
      });
      nextStep(successStepIndex);
      trackEvent<RegistrationSuccessEvent>({
        event: 'regconfirm',
        playerId: data.registerUser?.registrationData?.userProfileId as string,
      });
    },
    onError: (error) => {
      onRegistrationFailed(error);
    },
  });

  const selectedStep = registrationSteps[selectedStepIndex];

  const nextStep = (nextStepIndex: number): void => {
    /* istanbul ignore if */
    if (nextStepIndex < 0 || nextStepIndex >= registrationSteps.length) return;

    setPreviouslySelectedStepIndex(selectedStepIndex);
    setSelectedStepIndex(nextStepIndex);
  };

  const disableOtherSteps = (excludedStepId: string): void => {
    setStatus(
      keyBy(
        registrationSteps.map((step, stepNumber) => ({
          stepNumber,
          completed: false,
          enabled: step.id === excludedStepId,
        })),
        (s) => s.stepNumber
      )
    );
  };

  const previousStep = (): void => {
    if (selectedStepIndex === 0 || !selectedStep.backEnabled) return;

    setSelectedStepIndex(selectedStepIndex - 1);
  };

  const setSelectedStep = (stepId: string, firstStep?: boolean): void => {
    const isIntroduction = stepId === 'introduction';

    firstStep && nextStep(0);

    const stepIndex = registrationSteps.findIndex(equalsStepId(stepId));
    const step = registrationSteps[stepIndex];

    if (!step || (!status[stepIndex].completed && !isIntroduction)) return;

    if (isIntroduction && selectedStepIndex) {
      return previousStep();
    }

    nextStep(stepIndex);
  };

  const invalidateStep = (stepIndex: number): void => {
    const currentStepStatus = status[stepIndex];

    if (currentStepStatus.completed) {
      const step = registrationSteps[stepIndex];

      setSelectedStep(step.id);
      setStatus({
        ...status,
        [stepIndex]: { ...currentStepStatus, completed: false },
      });
    }
  };

  const completeStep = async (stepIndex: number, data: Partial<FlattenedProfileData>): Promise<void> => {
    if (formSubmitted.current) return;

    const step = registrationSteps[stepIndex];

    const nextFormState = {
      ...formState,
      ...updateFormDataByFieldConfig(data, formFields),
    };

    setFormState(nextFormState);
    setStatus({
      ...status,
      [stepIndex]: {
        ...status[stepIndex],
        completed: true,
      },
      [stepIndex + 1]: {
        ...status[stepIndex + 1],
        enabled: true,
      },
    });

    if (step.submissionStep) {
      if (loading) return;
      formSubmitted.current = true;

      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {
          toggleAllMarketing,
          optInEmail,
          optInSms,
          optInTelephone,
          promoCode,
          acceptTerms,
          givenName,
          familyName,
          ...others
        } = nextFormState;

        const phone = new PhoneNumber(nextFormState.mobileNumber, countryCodeOptions);
        const updatedPhoneNumber = phone.getPurePhoneNumber();
        const countryCode = phone.getCountryCodeValue();
        const currentGender = getGender(nextFormState.honorificPrefix);
        const parsedDate = parse(nextFormState.birthDate, 'dd/MM/yyyy', new Date());
        const dateOfBirth = formatDate(parsedDate, 'yyyy-MM-dd');

        disableOtherSteps('success');
        await registerUserMutation({
          variables: {
            model: {
              ...others,
              mobileNumber: updatedPhoneNumber,
              mobilePrefix: countryCode,
              utm_campaign: promoCode,
              gender: currentGender,
              optIn: acceptTerms,
              givenName: capitalizeFirstLetter(givenName),
              familyName: capitalizeFirstLetter(familyName),
              termsAndConditionsVersion: '1.0',
              birthDate: dateOfBirth,
            },
            registerUserXTenantId2: 'betzone_uk',
          },
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        onRegistrationFailed(e);
      }
    } else {
      nextStep(stepIndex + 1);
    }
  };

  const lastCompletedIndex = findLastIndex(registrationSteps, (_, i) => status[i] && status[i].completed);
  const redirectStepIndex = lastCompletedIndex === -1 ? 0 : lastCompletedIndex;

  const mergePartialData = (regData: Partial<FlattenedProfileData>): void => {
    setFormState({ ...formState, ...regData });
  };

  return {
    selectedStep,
    redirectStepIndex,
    selectedStepIndex,
    formState,
    status,
    previouslySelectedStepIndex,
    registrationSuccessResponse,
    registrationErrors,
    isSubmitting: loading,
    completeStep,
    invalidateStep,
    previousStep,
    mergePartialData,
    setSelectedStep,
  };
}
