import { CarouselContext } from 'pure-react-carousel';
import React, { FC, useContext, useEffect, useLayoutEffect, useRef, useState, useMemo } from 'react';
import noop from 'lodash/noop';
import { GameCard } from '../GameCard';
import { GameCardContainerType } from '../GameCard/GameCard.types';
import { useAssetLoadedListener } from '../../../hooks/helperHooks';
import { GameFilterCarousel, GamesList, GamesListProps, GamesListUIComponentProps } from '../GamesList';
import { Game, Statuses } from '../games.model';
import {
  MediaBreakpoint,
  Orientation,
  useBreakpointInfo,
  useDeviceInfo,
  useOrientation,
} from '../../../hooks/useDeviceInfo';
import { getCleopatraMegaJackpotsGame, getJackpotFeed } from '../mocks';
import { joinStrings } from '../../../utils/string';
import { groupedSlides } from '../../../utils/grouped-slides';
import { useGameSlidesCount } from '../../../hooks/games';
import { applyWideBanner, renderWideTileCarousel } from '../../../utils/wide-tile-carousel';
import { Spinner } from '../../shared/Spinner';
import { Carousel } from '../Carousel';
import { Heading } from '../../shared/Heading';
import { MobileGamesCarousel } from '../MobileGamesCarousel';
import './styles';

type CarouselProps = {
  size?: 'small' | 'medium';
  showArrows?: boolean;
  showDots?: boolean;
  overflow?: boolean;
  autoplay?: boolean;
  bigDesktopSlideCount: number;
  desktopSlideCount: number;
  tabletSlideCount: number;
  mobileSlideCount: number;
  autoplayInterval: number;
  gameCardContainerType: GameCardContainerType;
  disabled: boolean;
  gameLabel?: boolean;
  subtitle?: string;
  showDisplayName?: boolean;
  paginateByRow?: boolean;
  rowsNumber: number;
  showHeroBanner?: boolean;
  showWideHeroBanner?: boolean;
  gameBannerId?: string;
  applyBanner?: (slides: JSX.Element[][]) => (JSX.Element | JSX.Element[])[];
  isBanner?: boolean;
  showJackpotHeroBanner?: boolean;
  jackpotImageForDesktop?: string;
  jackpotImageForMobile?: string;
  jackpotId?: string;
  ctaButtonUrl?: string;
  gameOption?: GameFilterCarousel;
};

export type GamesCarouselProps = CarouselProps & GamesListProps & { isLoading: boolean; catalogue?: Game[] };

export const TickerComponent = (props: React.PropsWithChildren<GamesCarouselProps>): JSX.Element | null => {
  const jackpotData = getJackpotFeed();

  const isMobile = useDeviceInfo().isMobileDevice;
  const isNotCompleted = jackpotData?.status !== Statuses.Completed;
  const isStarted = jackpotData?.status !== Statuses.NotStarted;

  return (
    jackpotData && (
      <div className="ticker-wrapper">
        <div className="ticker-wrapper__text-complete">
          {jackpotData.endTime && (
            <p>
              {jackpotData?.status === Statuses.Completed
                ? 'Completed'
                : jackpotData?.status === Statuses.NotStarted
                  ? 'jackpot currency'
                  : null}
            </p>
          )}
        </div>
        <div
          className={joinStrings([
            isMobile && 'ticker-wrapper__ticker ticker--mobile',
            'ticker-wrapper__ticker',
            (!isNotCompleted || !isStarted) && 'ticker-wrapper__ticker ticker--completed',
          ])}
        >
          <div className="ticker-wrapper__ticker--trapezoid" />
          <p className="ticker-wrapper__ticker--trapezoid-text">£100.000</p>
        </div>
        {isNotCompleted && (
          <div className={joinStrings(['ticker-wrapper__timer', isMobile && 'ticker-wrapper__timer--mobile'])}>
            <p className="ticker-wrapper__timer--text">{jackpotData?.status}</p>
            <p className="ticker-wrapper__timer--time">{jackpotData?.time}</p>
          </div>
        )}
      </div>
    )
  );
};

const ListGamesCarousel: FC<{
  listProps: React.PropsWithChildren<GamesListUIComponentProps>;
  carouselProps: React.PropsWithChildren<GamesCarouselProps>;
  containerRef: React.RefObject<HTMLDivElement>;
  defaultNumberOfGames: number;
  category?: string;
  disabled: boolean;
  containerWidth: number;
  CarouselComponent: React.FC<
    GamesListProps &
      GamesListUIComponentProps &
      GamesCarouselProps & {
        games: Game[] | Game[][];
      } & {
        containerElementWidth: number;
      }
  >;
  gameBannerId?: string;
  isBanner?: boolean;
  isLoading: boolean;
  showWideHeroBanner?: boolean;
  showHeroBanner?: boolean;
  gameCardContainerType: string;
  showJackpotHeroBanner?: boolean;
  isMultiple?: boolean;
}> = (props) => {
  const {
    CarouselComponent,
    listProps,
    carouselProps,
    containerWidth,
    disabled,
    isLoading,
    gameBannerId,
    isBanner,
    showHeroBanner,
    showWideHeroBanner,
    gameCardContainerType,
    showJackpotHeroBanner,
    isMultiple,
  } = props;

  const { rowsNumber } = carouselProps;
  const [currentBannerGame, setCurrentBannerGame] = useState<Game>();

  useEffect(() => {
    if (gameBannerId) {
      const game = getCleopatraMegaJackpotsGame();

      setCurrentBannerGame(game);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const applyBanner = (slides: JSX.Element[][]): (JSX.Element | JSX.Element[])[] => {
    return currentBannerGame
      ? [
          <GameCard
            key={currentBannerGame.gameId}
            game={currentBannerGame}
            fallbackImageAltText=""
            fallbackImageUrl=""
            disabled={disabled}
            showDisplayName={false}
            showGameName={false}
            containerType={GameCardContainerType.Wide}
            banner={(!showJackpotHeroBanner && showHeroBanner) || showWideHeroBanner}
            gameUrl=""
          />,
          ...slides,
        ]
      : slides;
  };

  const groupedGames = groupedSlides(listProps.games as Game[], rowsNumber);

  return (
    <div
      className={joinStrings([
        'list-component',
        !showJackpotHeroBanner &&
          isBanner &&
          (showWideHeroBanner
            ? gameCardContainerType === GameCardContainerType.Tall
              ? 'list-component--square-banner' // Wide banner with tall tiles
              : 'list-component--wide-banner'
            : 'list-component--banner'),
      ])}
      ref={props.containerRef}
    >
      <CarouselComponent
        {...listProps}
        {...carouselProps}
        games={groupedGames}
        containerElementWidth={containerWidth}
        loading={isLoading}
        applyBanner={applyBanner}
        isBanner={isBanner}
        disabled={disabled}
        isMultiple={isMultiple}
      />
    </div>
  );
};

const CarouselCard: FC<
  GamesListUIComponentProps &
    GamesCarouselProps & {
      game: Game;
      containerElementWidth: number;
      slideIndex: number;
    }
> = ({
  games,
  containerElementWidth,
  size,
  fallbackImageAltText,
  showGameName,
  disableGameMenu,
  fallbackImageUrl,
  game,
  gameCardContainerType = GameCardContainerType.Tall,
  disabled,
  onViewGameInfo = noop,
  onPlayDemo = noop,
  onPlayGame = noop,
  gameLabel,
  isMultiple,
}) => {
  const carouselContext = useContext(CarouselContext);
  const { currentSlide, visibleSlides } = carouselContext.state;

  const visibleArraySlides = (index: number): boolean => {
    const visibleArray = games[index] as Game[];

    return visibleArray && visibleArray.includes(game as Game);
  };
  const firstVisibleSlide = visibleArraySlides(currentSlide);
  const lastVisibleSlide = visibleArraySlides(currentSlide + visibleSlides - 1);

  const transformOrigin = firstVisibleSlide ? 'center left' : lastVisibleSlide ? 'center right' : 'center center';

  return (
    <div className={joinStrings(['list-component', isMultiple && 'list-component__multi-carousel'])}>
      <GameCard
        containerElementWidth={containerElementWidth}
        size={size}
        containerType={gameCardContainerType}
        customTransformOrigin={transformOrigin}
        key={game.gameId}
        showGameName={showGameName}
        gameUrl=""
        game={game}
        disableMenu={disableGameMenu}
        fallbackImageAltText={fallbackImageAltText}
        fallbackImageUrl={fallbackImageUrl}
        onViewGameInfo={(): void => onViewGameInfo(game)}
        onPlayDemo={(): void => onPlayDemo(game)}
        onPlayGame={(): void => onPlayGame(game)}
        disabled={disabled}
        gameLabel={gameLabel ? 'simple' : ''}
        showDisplayName={false}
      />
    </div>
  );
};

const CustomCarousel: FC<GamesListUIComponentProps & GamesCarouselProps & { containerElementWidth: number }> = (
  props
) => {
  const {
    games,
    autoplayInterval,
    autoplay,
    overflow,
    showArrows = true,
    showDots = false,
    paginateByRow = true,
    mobileSlideCount = 2,
    tabletSlideCount = 4,
    desktopSlideCount = 6,
    bigDesktopSlideCount = 8,
    showTitle,
    title,
    containerElementWidth,
    disabled,
    defaultNumberOfGames = 6,
    subtitle,
    showDisplayName,
    loading,
    applyBanner,
    isBanner,
    showWideHeroBanner,
    showJackpotHeroBanner,
    isMultiple,
  } = props;

  const visibleSlidesNumber = useGameSlidesCount({
    wide: bigDesktopSlideCount,
    desktop: desktopSlideCount,
    tablet: tabletSlideCount,
    mobile: mobileSlideCount,
  });

  const [tabletJackpotSlideCount, setTabletJackpotSlideCount] = useState(tabletSlideCount);

  useEffect(() => {
    showJackpotHeroBanner && setTabletJackpotSlideCount(2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const slides = (games as Game[][]).map((slide) => {
    return slide.map((game, i) => {
      return (
        <CarouselCard
          {...props}
          isMultiple={isMultiple}
          games={games}
          key={game.gameId}
          slideIndex={i}
          containerElementWidth={containerElementWidth}
          game={game}
          showDisplayName={showDisplayName}
          gameLabel={props.recent}
        />
      );
    });
  });

  const groupedBanner = applyBanner && applyBanner(slides);

  const { banner, slidesUnderBanner, slidesWithoutBanner } = applyWideBanner(groupedBanner as JSX.Element[][]);

  const wideTileCarousel =
    slidesUnderBanner &&
    renderWideTileCarousel({
      isBanner,
      slidesWithoutBanner,
      slidesUnderBanner,
      banner,
    });

  const dotsCanBeShown = paginateByRow ? defaultNumberOfGames / visibleSlidesNumber > 1 : defaultNumberOfGames > 1;

  return loading ? (
    <Spinner />
  ) : slides.length ? (
    <Carousel
      adjustSlidesOnResize
      title={!showJackpotHeroBanner && showTitle ? title : undefined}
      subtitle={subtitle}
      step={visibleSlidesNumber}
      showDots={dotsCanBeShown && showDots}
      {...{
        paginateByRow,
        showArrows,
        autoplay,
        autoplayInterval,
        visibleConfig: {
          desktop: desktopSlideCount,
          wide: bigDesktopSlideCount,
          mobile: mobileSlideCount,
          tablet: tabletJackpotSlideCount,
        },
      }}
      alignment="center"
      offsetPercentage={overflow ? 3 : 0}
      slides={
        !showJackpotHeroBanner ? (showWideHeroBanner ? wideTileCarousel : applyBanner && applyBanner(slides)) : slides
      }
      disableSlideContent={disabled}
      allowGridMode
    />
  ) : null;
};

// TODO: update this component
export const GamesCarouselList: FC<GamesCarouselProps> = (props) => {
  const {
    gameOptionValue,
    defaultNumberOfGames = 6,
    rowsNumber,
    showHeroBanner,
    gameBannerId,
    showWideHeroBanner,
    gameCardContainerType,
    showJackpotHeroBanner,
    jackpotImageForDesktop,
    showDots,
    gameOption,
    catalogue,
    disabled,
    isLoading,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const { isMobileDevice, isTablet } = useDeviceInfo();
  const isLandscape = useOrientation() === Orientation.LANDSCAPE;
  const isMobile = useBreakpointInfo([MediaBreakpoint.BELOW_MOBILE]);
  const isMultiple = rowsNumber > 1;

  const isSingleRow = isMobileDevice && !isTablet && !isLandscape;
  const isBanner = Boolean(rowsNumber === 2 && (showHeroBanner || showWideHeroBanner) && gameBannerId);

  const [containerWidth, setContainerWidth] = useState(window.innerWidth);

  useLayoutEffect(() => {
    setContainerWidth(containerRef.current?.clientWidth || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current]);

  const NamedListComponent = (listProps: GamesListUIComponentProps): JSX.Element => {
    const { assetLoaded, onAssetLoaded } = useAssetLoadedListener([jackpotImageForDesktop]);

    return (
      <>
        {showJackpotHeroBanner && !isMobile && (
          <div className="carousel-wrapper__header">
            <Heading data-testid="carousel-wrapper-title" className="carousel-wrapper__title" level={4}>
              {props.title}
            </Heading>
          </div>
        )}
        <div className="games-list__wrapper">
          {showJackpotHeroBanner && jackpotImageForDesktop && !isMobile && (
            <div
              className={joinStrings([
                'games-list__jackpot-banner',
                !showDots && 'games-list__jackpot-banner--dots',
                props.gameCardContainerType === 'wide' && 'games-list__jackpot-banner_wide-tile',
                isSingleRow && 'games-list__jackpot-banner_single-row',
                props.gameCardContainerType === 'wide' &&
                  isSingleRow &&
                  'games-list__jackpot-banner_single-row_wide-tile',
              ])}
            >
              <img
                className={joinStrings([
                  'games-list__jackpot-banner-img',
                  'games-list__jackpot-banner-img--skeleton',
                  !assetLoaded && 'games-list__jackpot-banner-img--skeleton--animated',
                ])}
                src={jackpotImageForDesktop}
                alt="Desktop jackpot banner"
                onLoad={onAssetLoaded}
              />
              <TickerComponent {...props} />
            </div>
          )}
          <ListGamesCarousel
            isMultiple={isMultiple}
            isLoading={isLoading}
            listProps={listProps}
            carouselProps={props}
            disabled={disabled}
            containerRef={containerRef}
            containerWidth={containerWidth}
            category={gameOption === GameFilterCarousel.Category ? gameOptionValue : undefined}
            defaultNumberOfGames={defaultNumberOfGames}
            CarouselComponent={
              // eslint-disable-next-line react-hooks/rules-of-hooks, react-hooks/exhaustive-deps
              isMobile ? useMemo(() => MobileGamesCarousel, [isMobile, containerWidth, disabled]) : CustomCarousel
            }
            gameBannerId={gameBannerId}
            showHeroBanner={showHeroBanner}
            isBanner={isBanner}
            showWideHeroBanner={showWideHeroBanner}
            gameCardContainerType={gameCardContainerType}
            showJackpotHeroBanner={showJackpotHeroBanner}
          />
        </div>
      </>
    );
  };

  return (
    <GamesList
      {...props}
      showTitle={false}
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ListComponent={useMemo(() => NamedListComponent, [isMobile, containerWidth, disabled])}
      catalogue={catalogue}
      GameComponent={GameCard}
      activeSubNavCategory={undefined}
    />
  );
};
