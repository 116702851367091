import { TrackingEvent } from './types';

export const trackEvent = <T extends TrackingEvent>(eventData: T): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventData);
};

// Need to extend Window to include dataLayer
declare global {
  interface Window {
    dataLayer: TrackingEvent[];
  }
}
