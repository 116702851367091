const getAuthUrl = (): string => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'DEV':
      return 'https://dev.betzone.co.uk/auth';
    case 'STAGE':
      return 'https://stg.betzone.co.uk/auth';
    case 'PRODUCTION':
      return 'https://betzone-prod.pages.dev/auth';
    default:
      console.warn(`Unknown environment: ${process.env.REACT_APP_ENVIRONMENT}. Defaulting to development auth URL.`);

      return 'https://betzone.pages.dev/auth';
  }
};

const realm: string = process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION' ? 'betzone_uk' : 'betzone';

export const config = {
  realm: realm,
  authUrl: getAuthUrl(),
  clientId: 'web-betzone_uk',
  tenantId: 'betzone_uk',
};
