import React, { FC, useEffect } from 'react';
import noop from 'lodash/noop';
import { Icon, IconVariant } from '../Icon';
import { joinStrings } from '../../../utils/string';
import { Button } from '../Button';
import './NotificationMessage.scss';

interface NotificationMessageProps {
  className?: string;
  iconClass?: string;
  buttonClass?: string;
  iconVariant: IconVariant;
  text: string;
  buttonText?: string;
  onClick?: () => void;
  onShow?: () => void;
}

export const NotificationMessage: FC<NotificationMessageProps> = ({
  className,
  iconClass,
  iconVariant,
  text,
  buttonClass,
  buttonText,
  onClick,
  onShow = noop,
}) => {
  useEffect(() => {
    onShow();
  }, []);

  return (
    <div className={joinStrings(['notification-message', className])}>
      <Icon variant={iconVariant} className={joinStrings(['notification-message__icon', iconClass])} />
      <span>{text}</span>
      <Button
        className={joinStrings(['notification-message__button', buttonClass])}
        type="button"
        variant="text"
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </div>
  );
};
